<template>
  <v-btn
    v-if="user.is_teacher"
    class="mt-4 mr-3"
    color="primary"
    large
    @click="click()"
  >
    <v-icon class="mr-4">mdi-plus</v-icon>
    Ajouter un cours
  </v-btn>
</template>

<script>
export default {
  name: "CardCourse",
  props: {
    user: Object,
    isAdminMode: Boolean,
  },
  methods: {
    click: function () {
      if (!this.isAdminMode) {
        this.$router.push("/createcourse");
      } else {
        this.$router.push("/createcourse/" + this.user._id);
      }
    },
  },
};
</script>
