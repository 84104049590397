//import $axios from "../plugins/axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    localOpeningState: null,
  },
  mutations: {
    LOCALOPENINGSTATE(state, localOpeningState) {
      state.localOpeningState = localOpeningState;
    },
  },
  actions: {
    localOpeningState(context) {
      Vue.axios
        .get("local/")
        .then((response) => {
          context.commit("LOCALOPENINGSTATE", response.data.state);
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Quelque chose c'est mal passé 🤔",
            footer:
              "Si le problème persiste contacte LéoLearning avec le code: 9",
          });
        });
    },
    changeLocalOpeningState(context) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("local/")
          .then((response) => {
            context.commit("LOCALOPENINGSTATE", response.data.state);
            resolve(response.data.state);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 22",
            });
            reject();
          });
      });
    },
  },
  getters: {
    getLocalOpeningState(state) {
      return state.localOpeningState;
    },
  },
};
