<template>
  <div class="background">
    <!-- <div class="text-center mt-4 center">
      <<v-alert type="warning" max-width="650" class="d-md-block center d-none"
        >Le site est toujours en cours de développement, il sera disponible
        rapidement !</v-alert
      > 
    </div>-->
    <v-container v-if="!isFetching">
      <v-row>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" md="6">
              <v-card elevation="2" min-height="750" class="px-4 py-4">
                <Bio :user="user" :adminMod="this.adminMod" />
                <v-divider
                  class="lighten-1 mt-5 mb-3 ml-0 mr-0"
                  v-bind:class="schoolColor"
                ></v-divider>
                <StatsProfile :user="user" />
                <div v-if="user.is_teacher">
                  <v-divider
                    class="lighten-1 mt-5 mb-3 ml-0 mr-0"
                    v-bind:class="schoolColor"
                  ></v-divider>
                  <BtnAddCourse :user="user" :isAdminMode="adminMod" />
                  <v-btn
                    color="warning"
                    class="mt-4"
                    large
                    @click="dialog_edit_teacher = true"
                  >
                    <v-icon class="mr-1">mdi-pencil</v-icon> Modifier
                    préférences
                  </v-btn>
                  <v-dialog v-model="dialog_edit_teacher" width="600">
                    <DialogTeacherProfiles
                      :user="user"
                      @close="dialog_edit_teacher = false"
                    />
                  </v-dialog>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                height="750"
                class="overflow-y-auto pa-2 mb-8"
                v-scroll.self="onScroll"
              >
                <CourseApplicationViewer :user="user" :adminMod="adminMod" />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Bio from "../components/Bio.vue";
import StatsProfile from "../components/StatsProfile.vue";
import CourseApplicationViewer from "../components/CourseApplicationViewer.vue";
import DialogTeacherProfiles from "../components/DialogTeacherProfiles.vue";
import BtnAddCourse from "../components/BtnAddCourse.vue";
import { mapGetters } from "vuex";
// import dayjs from "dayjs";

export default {
  name: "Profile",

  components: {
    DialogTeacherProfiles,
    Bio,
    StatsProfile,
    CourseApplicationViewer,
    BtnAddCourse,
  },

  data: () => ({
    isFetching: true,
    adminMod: false,
    dialog_edit_teacher: false,

    scrollInvoked: 0,
  }),
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
    }),
    schoolColor: function () {
      return this.user.school;
    },
    user: function () {
      if (this.$route.params.memberid == undefined) {
        return this.currentUser;
      } else {
        return this.specificUser;
      }
    },
  },
  methods: {
    returnSchool() {
      return this.user.school;
    },
    onScroll() {
      this.scrollInvoked++;
    },
  },
  beforeMount() {
    if (this.$route.params.memberid == undefined) {
      this.isFetching = false;
    } else {
      this.adminMod = true;
      this.$store
        .dispatch("admin/getUserById", this.$route.params.memberid)
        .finally(() => {
          this.isFetching = false;
        });
    }
  },
  mounted() {
    //#region POPUP RECRUTEMENT V2
    // if (
    //   localStorage.getItem("popup-recrutement2") == null ||
    //   parseInt(localStorage.getItem("popup-recrutement2")) <= 2
    // ) {
    //   this.$swal
    //     .fire({
    //       title:
    //         "<label style='font-size: 3em'>🔥</label><br>LéoLearning recrute !",
    //       html: `
    //       Envie de rejoindre l'asso de tutorat du pôle ?<br><br>
    //       Que tu aies envie de <strong>partager tes connaissances</strong> 🤯, que tu sois un.e <strong>artiste en graphisme</strong> 💪, ou un.e <strong>maître de l'organisation d'événements</strong> 📑,<br> il y a une place pour toi 👍 !`,
    //       confirmButtonText:
    //         "<a style='text-decoration: none; color: #ffffff' >🚀 Rejoins l'aventure Léolearning 🚀</a>",
    //       confirmButtonColor: "#ca8c05",
    //       showCloseButton: true,
    //       iconColor: "#ca8c05",
    //     })
    //     .then((okay) => {
    //       console.log(okay);
    //       if (okay.isConfirmed) {
    //         window.open("https://forms.gle/jn7rphm3S5q9hDkR9", "_blank");
    //       }
    //     });
    //   if (localStorage.getItem("popup-recrutement2") == null) {
    //     localStorage.setItem("popup-recrutement2", "1");
    //   } else {
    //     localStorage.setItem(
    //       "popup-recrutement2",
    //       (parseInt(localStorage.getItem("popup-recrutement2")) + 1).toString()
    //     );
    //   }
    // }
    //#endregion
    //#region POPUP RECRUTEMENT V1
    // if ([null, "1", "2"].includes(localStorage.getItem("popup-1"))) {
    //   this.$swal
    //     .fire({
    //       icon: "info",
    //       title: "LéoLearning recrute ! ",
    //       html: "Tu veux t’investir dans une association d’entraide entre étudiants ? <br/> Que ce soit pour créer des visuels, organiser des évènements ou aider pour le tutorat, n'hésite pas et rejoins-nous !!",
    //       confirmButtonText:
    //         "<a style='text-decoration: none; color: #ffffff' >GO !!</a>",
    //       confirmButtonColor: "#ca8c05",
    //       iconColor: "#ca8c05",
    //     })
    //     .then((okay) => {
    //       console.log(okay);
    //       if (okay.isConfirmed) {
    //         window.open("https://forms.gle/6x9HB5tPNK3iYU8D6", "_blank");
    //       }
    //     });
    //   switch (localStorage.getItem("popup-1")) {
    //     case null:
    //       localStorage.setItem("popup-1", "1");
    //       break;
    //     case "1":
    //       localStorage.setItem("popup-1", "2");
    //       break;
    //     case "2":
    //       localStorage.setItem("popup-1", "3");
    //       break;
    //   }
    // }
    //#endregion
    //#region POPUP NOEL
    // if (
    //   !localStorage.getItem("popup-noel") ||
    //   dayjs().format("YYYY-MM-DD") !=
    //     dayjs(localStorage.getItem("popup-noel")).format("YYYY-MM-DD")
    // )
    //   this.$swal
    //     .fire({
    //       title:
    //         "<label style='font-size: 3em'>🎄</label><br>LéoLearning dévoile son <label style='color: #056517'>calendrier de l'avent</label> !",
    //       html: "Tous les jours du <strong style='color: #056517'>1er au 25 décembre</strong> retrouvez<br>des <strong style='color: #056517'>quiz</strong>, des <strong style='color: #056517'>devinettes</strong> et des <strong style='color: #056517'>enigmes</strong> sur notre compte Instagram.",
    //       confirmButtonText: "Aller sur Instagram🎁",
    //       confirmButtonColor: "#BB051F",
    //       iconColor: "#BB051F",
    //       showCloseButton: true,
    //       backdrop: "rgba(194,95,95,0.4)",
    //     })
    //     .then((okay) => {
    //       if (okay.isConfirmed) {
    //         window.open("https://www.instagram.com/leolearningpulv/", "_blank");
    //       }
    //     });
    // localStorage.setItem("popup-noel", dayjs());
    //#endregion
    //#region POPUP PUB LEODATA
    // if (
    //   !localStorage.getItem("popup-noel") ||
    //   dayjs().format("YYYY-MM-DD") !=
    //     dayjs(localStorage.getItem("popup-noel")).format("YYYY-MM-DD")
    // )
    // this.$swal
    //   .fire({
    //     title: `<img src='../assets/leodata_logo.png'>
    //     <div style='margin-top: -20px'><label style='color:#0899BD'>LÉO</label><label style='color:#D11758'>DA</label><label style='color:#EF8108'>TA</label></div>`,
    //     html: `
    //       Notre plateforme d'annales pour <strong style='color:#D11758'>l'ESILV</strong> et <strong style='color:#0899BD'>l'EMLV</strong> a besoin de toi !<br><br>
    //       Si tu as gardé des <strong>sujets</strong> ou des <strong>corrections</strong>,
    //       envoie-les nous par mail <a href="mailto:leolearning@devinci.fr">leolearning@devinci.fr</a> <strong>OU</strong> sur <a href="https://www.instagram.com/direct/t/17843044025446259" target="_blank">Instagram</a>
    //       `,
    //     confirmButtonText: "OK",
    //     showCloseButton: true,
    //   })
    //   .then((okay) => {
    //     if (okay.isConfirmed) {
    //       window.open("https://www.instagram.com/leolearningpulv/", "_blank");
    //     }
    //   });
    // localStorage.setItem("popup-noel", dayjs());
    //#endregion
  },
};
</script>

<style scoped>
.background {
  background-color: #d6d6d6;
  background-image: url(../assets/bg_white_wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: auto;
}

.center {
  margin: auto;
  width: 60%;
}

@media screen and (max-width: 1200px) {
  .responsive {
    display: flex;
    flex-direction: column;
  }

  .stats {
    display: flex;
    flex-direction: column;
  }
}
</style>
