import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import $axios from "./plugins/axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

Vue.config.productionTip = false;

Vue.use(VueAxios, $axios);

Vue.use(VueSweetalert2);

$axios
  .get("/auth/isLoggedIn")
  .then((res) => {
    console.log(res.data.user);
    store.dispatch("user/loginState", true);
    store.dispatch("user/user", res.data.user);
  })
  .catch(() => {
    store.dispatch("user/loginState", false);
    store.dispatch("user/user", {});
  })
  .finally(() => {
    $axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error.response.status === 401) {
          //Todo: Add if error code == 401 => Sessions expiré / 403 ==> Pas authorisé
          store.dispatch("user/loginState", false);
          store.dispatch("user/user", {});

          if (
            router.currentRoute.fullPath != "/" ||
            router.currentRoute.fullPath != "/verify" ||
            router.currentRoute.fullPath != "/backdoor" ||
            router.currentRoute.fullPath != "/about"
          ) {
            router.push("/");
          }
        }
        return Promise.reject(error);
      }
    );

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
