<template>
  <div>
    <div class="mx-4">
      <v-container>
        <v-row class="mt-1">
          <v-col cols="12" sm="8">
            <v-autocomplete
              :items="possiblestatus"
              prepend-icon="mdi-list-status"
              multiple
              large
              dense
              label="Statut"
              v-model="filteredstatus"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  class="mx-1 my-1"
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete></v-col
          >
          <v-col cols="12" sm="4" class="mt-n2">
            <v-checkbox
              class="d-md-inline-flex mr-4 mb-n4"
              label="ESILV"
              value="ESILV"
              v-model="schools"
            ></v-checkbox>
            <v-checkbox
              class="d-md-inline-flex mr-4 mb-n4"
              label="EMLV"
              value="EMLV"
              v-model="schools"
            ></v-checkbox>
            <v-checkbox
              class="d-md-inline-flex mb-n4"
              label="IIM"
              value="IIM"
              v-model="schools"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="name"
              label="Rechercher"
              prepend-icon="mdi-magnify"
              placeholder="Rechercher"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Filtre date début et fin"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  clearable
                  prepend-icon="mdi-calendar"
                  @click:clear="date = []"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :first-day-of-week="1"
                range
                locale="fr-fr"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3"> </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="overflow-y-auto" style="height: 500px">
      <CardCourse
        class="mx-4 my-4"
        v-for="course in filteredCourse"
        :key="course._id"
        :course="course"
        :isAdminMode="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardCourse from "./CardCourse.vue";

export default {
  name: "CourseViewer",
  components: {
    CardCourse,
  },
  data() {
    return {
      scrollInvoked: 0,
      menu: false,

      possiblestatus: [
        "En attente de confirmation",
        "Confirmé",
        "En cours",
        "Terminé",
        "Annulé",
        "Annulé après la sélection",
      ],
      filteredstatus: ["En attente de confirmation", "Confirmé", "En cours"],
      schools: ["ESILV", "EMLV", "IIM"],
      name: "",

      date: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allCourses: "admin/getAllCourses",
    }),
    filteredCourse: function () {
      //! TODO Filtre en ligne ou présentiel
      let res = this.allCourses.filter((course) => {
        return this.complyWithFilter(course);
      });

      res.sort((a, b) => {
        let a_date = a.start_date;
        let b_date = b.start_date;

        if (Date.parse(a_date) > Date.parse(b_date)) {
          return -1;
        } else {
          return 1;
        }
      });

      return res;
    },
    computedDateFormatted: {
      get() {
        let res = [];
        for (let elem of this.date) {
          res.push(this.formatDate(elem));
        }
        return res.join(" 👉 ");
      },
      set() {
        this.date = [];
      },
    },
  },
  methods: {
    remove(item) {
      const index = this.filteredstatus.indexOf(item);
      if (index >= 0) this.filteredstatus.splice(index, 1);
    },
    onScroll() {
      this.scrollInvoked++;
    },
    redirectCourse(id) {
      this.$router.push("/modifycourse/" + id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    complyWithFilter(course) {
      //Status filter
      if (!this.filteredstatus.includes(course.status)) {
        if (
          course.status != "Annulé" ||
          !course.is_cancel_after_selection ||
          !this.filteredstatus.includes("Annulé après la sélection")
        ) {
          return false;
        }
      }

      let school = course.teacher.school;

      //School filter
      if (!this.schools.includes(school)) {
        return false;
      }

      //Date filter
      let course_date = new Date(course.start_date);

      switch (this.date.length) {
        case 1: {
          //Course must start after date
          let start_date = new Date(this.date[0]);
          if (start_date.valueOf() > course_date.valueOf()) {
            return false;
          }
          break;
        }
        case 2: {
          //Course must start after date
          let start_date = new Date(this.date[0]);
          let end_date = new Date(this.date[1]);
          if (
            start_date.valueOf() > course_date.valueOf() ||
            end_date.valueOf() < course_date.valueOf()
          ) {
            return false;
          }
          break;
        }
      }

      //Search bar filter
      //Create names string for a course

      if (this.name != null && this.name != "") {
        let studentsNameString = this.studentsName(course);
        if (!studentsNameString.includes(this.name.toLowerCase())) {
          return false;
        }
      }
      return true;
    },
    studentsName(course) {
      let res =
        course.teacher.firstname.toLowerCase() +
        " " +
        course.teacher.firstname.toUpperCase();
      if (course.status == "En attente de confirmation") {
        res += course.applications
          .map((application) => {
            return (
              application.applicant.firstname.toLowerCase() +
              " " +
              application.applicant.lastname.toLowerCase()
            );
          })
          .join(" ");
      }

      if (
        course.status === "Confirmé" ||
        course.status === "En cours" ||
        course.status === "Terminé"
      ) {
        res += course.selected
          .map((application) => {
            return (
              application.applicant.firstname.toLowerCase() +
              " " +
              application.applicant.lastname.toLowerCase()
            );
          })
          .join(" ");
        res += course.refused
          .map((application) => {
            return (
              application.applicant.firstname.toLowerCase() +
              " " +
              application.applicant.lastname.toLowerCase()
            );
          })
          .join(" ");
        res += course.missing
          .map((application) => {
            return (
              application.applicant.firstname.toLowerCase() +
              " " +
              application.applicant.lastname.toLowerCase()
            );
          })
          .join(" ");
      }
      return res;
    },
  },
  beforeMount: function () {
    this.$store.dispatch("admin/importAllCourses", {
      limit: 99,
      page: 1,
    });
  },
};
</script>
