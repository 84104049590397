<template>
  <div>
    <div v-if="application.status == 'En attente' && !modifyCourse">
      <v-card color="#ECFEF2" elevation="2" @click="dialog_edit = true">
        <v-card-title class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-subtitle class="text-left wb">
          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation provisoire:
          {{ application.format_preference.join(", ") }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog_edit" width="600">
        <DialogApplication
          :application="application"
          @close="dialog_edit = false"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'En attente' && modifyCourse">
      <v-card color="#ECFEF2" elevation="2">
        <v-card-title class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-subtitle class="text-left wb">
          <v-icon>mdi-account</v-icon>
          {{ application.applicant.firstname }}
          {{ application.applicant.lastname }} |
          {{ application.applicant.school }} - {{ application.applicant.year }}
          <br />
          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation voulue:
          {{ application.format_preference.join(", ") }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialog_edit" width="600">
        <DialogApplication
          :application="application"
          @close="dialog_edit = false"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'Cours annulé'">
      <v-card color="#fa9d9d" elevation="2">
        <v-card-title class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-subtitle class="text-left wb">
          <span v-if="modifyCourse">
            <v-icon>mdi-account</v-icon>
            {{
              application.applicant.firstname +
              " " +
              application.applicant.lastname
            }}
            <br />
          </span>

          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-information</v-icon> Le cours a été annulé par le tuteur
        </v-card-subtitle>
        <v-card-actions
          v-if="course_status == 'Confirmé' && adminMod"
          class="mt-n4"
        >
          <v-btn
            @click="swap_refused(application._id)"
            elevation="2"
            color="success"
          >
            <v-icon>mdi-checkbox-marked</v-icon> Accepter la demande
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog_swap_refused" width="600">
        <DialogSwapRefusedApplication
          :application="application"
          @close="dialog_swap_refused = false"
          :refuse_application="false"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'Acceptée' && !modifyCourse">
      <v-card color="#ECFEF2" elevation="2" @click="dialog_edit = true">
        <v-card-title v-if="!course_done" class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-title v-else class="wb"> Cours terminé </v-card-title>
        <v-card-subtitle v-if="!modifyCourse" class="text-left wb">
          <v-icon>mdi-account</v-icon>
          Tuteur:
          {{ application.course.teacher.firstname }}
          {{ application.course.teacher.lastname }} |
          {{ application.course.teacher.school }} -
          {{ application.course.teacher.year }}
          <br />
          <v-icon>mdi-book</v-icon>
          Matières sélectionné:
          {{ application.course.material.name }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <span v-if="application.course.format == 'Présentiel'">
            <v-icon>mdi-map-marker</v-icon> Localisation :
            {{ application.course.format }}
            <span v-if="application.course.room == ''">
              | La salle te sera communiqué bientôt !
            </span>
            <span v-else> | {{ application.course.room }} </span>
          </span>
          <span v-else-if="application.course.format == 'Hybride'">
            <v-icon>mdi-map-marker</v-icon> Localisation :
            {{ application.course.format }} <br />
            En ligne sur discord <br />
            <span v-if="application.course.room == ''">
              La salle te sera communiqué bientôt !
            </span>
            <span v-else> En présentiel en {{ application.course.room }} </span>
          </span>
          <span v-else>
            <v-icon>mdi-map-marker</v-icon> Localisation :
            {{ application.course.format }} sur discord
          </span>
        </v-card-subtitle>
        <v-card-subtitle v-else class="text-left wb mb-n2">
          <v-icon>mdi-account</v-icon>
          {{
            application.applicant.firstname +
            " " +
            application.applicant.lastname
          }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
          <br />
          <span v-if="course_done"
            ><span class="text-body-1">Feedback du cours: </span
            >{{ application.feedback }}</span
          >
        </v-card-text>
        <v-card-actions
          v-if="course_status == 'Confirmé' && adminMod"
          class="mt-n4"
        >
          <v-btn
            @click="swap_refused(application._id)"
            elevation="2"
            color="error"
          >
            <v-icon>mdi-delete</v-icon> Refuser la demande
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="
            (course_status == 'En cours' || course_status == 'Terminé') &&
            adminMod
          "
          class="mt-n4"
        >
          <v-btn
            @click="swap_missing(application._id)"
            elevation="2"
            color="error"
          >
            <v-icon>mdi-delete</v-icon> Mettre absent
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog_swap_refused" width="600">
        <DialogSwapRefusedApplication
          :application="application"
          @close="dialog_swap_refused = false"
          :refuse_application="true"
          :adminMod="adminMod"
        />
      </v-dialog>
      <v-dialog v-model="dialog_swap_missing" width="600">
        <DialogSwapMissingApplication
          :application="application"
          @close="dialog_swap_missing = false"
          :missing="true"
          :adminMod="adminMod"
        />
      </v-dialog>
      <v-dialog v-model="dialog_edit" width="600">
        <DialogApplication
          :application="application"
          @close="dialog_edit = false"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'Acceptée' && modifyCourse">
      <v-card color="#ECFEF2" elevation="2">
        <v-card-title class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-subtitle v-if="!modifyCourse" class="text-left wb">
          <v-icon>mdi-book</v-icon>
          Matières sélectionné:
          {{ application.course.material.name }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation :
          {{ application.course.format }}
        </v-card-subtitle>
        <v-card-subtitle v-else class="text-left wb mb-n2">
          <v-icon>mdi-account</v-icon>
          {{
            application.applicant.firstname +
            " " +
            application.applicant.lastname
          }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
          <br />
          <span v-if="course_done"
            ><span class="text-body-1">Feedback du cours: </span
            >{{ application.feedback }}</span
          >
        </v-card-text>
        <v-card-actions
          v-if="course_status == 'Confirmé' && adminMod"
          class="mt-n4"
        >
          <v-btn
            @click="swap_refused(application._id)"
            elevation="2"
            color="error"
          >
            <v-icon>mdi-delete</v-icon> Refuser la demande
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="course_status == 'En cours' || course_status == 'Terminé'"
          class="mt-n4"
        >
          <v-btn
            @click="swap_missing(application._id)"
            elevation="2"
            color="error"
          >
            <v-icon>mdi-delete</v-icon> Mettre absent
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog_swap_refused" width="600">
        <DialogSwapRefusedApplication
          :application="application"
          @close="dialog_swap_refused = false"
          :refuse_application="true"
          :adminMod="adminMod"
        />
      </v-dialog>
      <v-dialog v-model="dialog_swap_missing" width="600">
        <DialogSwapMissingApplication
          :application="application"
          @close="dialog_swap_missing = false"
          :missing="true"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'Absent'">
      <v-card color="#ffd19c" elevation="2">
        <v-card-title class="wb">
          {{ application.status }}
        </v-card-title>
        <v-card-subtitle v-if="!modifyCourse" class="text-left wb">
          <v-icon>mdi-book</v-icon>
          Matières sélectionné:
          {{ application.course.material.name }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation :
          {{ application.course.format }}
        </v-card-subtitle>
        <v-card-subtitle v-else class="text-left wb mb-n2">
          <v-icon>mdi-account</v-icon>
          {{
            application.applicant.firstname +
            " " +
            application.applicant.lastname
          }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
        </v-card-text>
        <v-card-actions
          v-if="course_status == 'Confirmé' && adminMod"
          class="mt-n4"
        >
          <v-btn
            @click="swap_refused(application._id)"
            elevation="2"
            color="error"
          >
            <v-icon>mdi-delete</v-icon> Refuser la demande
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="
            (course_status == 'En cours' || course_status == 'Terminé') &&
            adminMod
          "
          class="mt-n4"
        >
          <v-btn
            @click="swap_missing(application._id)"
            elevation="2"
            color="success"
          >
            <v-icon>mdi-checkbox-marked</v-icon> Mettre présent
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog_swap_refused" width="600">
        <DialogSwapRefusedApplication
          :application="application"
          @close="dialog_swap_refused = false"
          :refuse_application="true"
          :adminMod="adminMod"
        />
      </v-dialog>
      <v-dialog v-model="dialog_swap_missing" width="600">
        <DialogSwapMissingApplication
          :application="application"
          @close="dialog_swap_missing = false"
          :missing="false"
          :adminMod="adminMod"
        />
      </v-dialog>
    </div>
    <div v-else-if="application.status == 'Annulée'">
      <v-card color="#fa9d9d" elevation="2">
        <v-card-title class="wb">
          <span v-if="modifyCourse"
            >{{ application.status }} par l'étudiant</span
          >
          <span v-else>{{ application.status }}</span>
        </v-card-title>
        <v-card-subtitle v-if="!modifyCourse" class="text-left wb">
          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation voulue:
          {{ application.format_preference.join(", ") }}
        </v-card-subtitle>
        <v-card-subtitle v-else class="text-left wb mb-n2">
          <v-icon>mdi-account</v-icon>
          {{
            application.applicant.firstname +
            " " +
            application.applicant.lastname
          }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="application.status == 'Refusée'">
      <v-card color="#fa9d9d" elevation="2">
        <v-card-title class="wb">
          <span v-if="modifyCourse"
            >{{ application.status }} par le matchmaking</span
          >
          <span v-else>{{ application.status }}</span>
        </v-card-title>
        <v-card-subtitle v-if="!modifyCourse" class="text-left wb">
          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
          <br />
          <v-icon>mdi-calendar</v-icon>
          {{ start_date_formated }} 👉
          {{ end_date_formated }}
          <br />
          <v-icon>mdi-map-marker</v-icon> Localisation provisoire:
          {{ application.format_preference.join(", ") }}
        </v-card-subtitle>
        <v-card-subtitle v-else class="text-left wb mb-n2">
          <v-icon>mdi-account</v-icon>
          {{
            application.applicant.firstname +
            " " +
            application.applicant.lastname
          }}
          <br />
          <v-icon>mdi-book</v-icon>
          Matières souhaitées:
          {{
            application.materials
              .map((material) => {
                return material.name;
              })
              .join(", ")
          }}
        </v-card-subtitle>
        <v-card-text>
          <span class="text-body-1">Message pour le tuteur: </span>
          {{ application.description }}
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-card color="#ECFEF2" elevation="2">
        {{ application.status }}
      </v-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DialogApplication from "../components/DialogApplication.vue";
import DialogSwapRefusedApplication from "./DialogSwapRefusedApplication.vue";
import DialogSwapMissingApplication from "./DialogSwapMissingApplication.vue";

export default {
  name: "CardApplication",
  components: {
    DialogApplication,
    DialogSwapRefusedApplication,
    DialogSwapMissingApplication,
  },
  props: {
    application: Object,
    adminMod: Boolean,
    modifyCourse: Boolean,
    course_status: String,
  },
  data: () => ({
    dialog_edit: false,
    dialog_swap_refused: false,
    dialog_swap_missing: false,
  }),
  methods: {
    swap_refused() {
      this.dialog_swap_refused = true;
    },
    swap_missing() {
      this.dialog_swap_missing = true;
    },
  },
  computed: {
    start_date_formated: function () {
      return dayjs(this.application.course.start_date).format(
        "DD-MM-YYYY HH:mm"
      );
    },
    end_date_formated: function () {
      return dayjs(this.application.course.end_date).format("HH:mm");
    },
    course_done: function () {
      return (
        this.application.course.status == "Terminé" ||
        this.application.course.status == "En cours" ||
        this.course_status == "Terminé" ||
        this.course_status == "En cours"
      );
    },
  },
};
</script>

<style scoped>
.v-card--link:before {
  background: none;
}

.divider-h {
  border-width: 2px;
}

.wb {
  word-break: normal;
}
</style>
