<template>
  <v-sheet elevation="2" class="mx-4 my-4 mb-2 rounded-lg">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" @click="detail()">
          <div class="mt-1">
            <v-icon>mdi-account</v-icon> {{ user.firstname }}
            {{ user.lastname }} | {{ user.school }} -
            {{ user.year }}
          </div>
          <div>
            <v-icon>mdi-calendar</v-icon> Date d'inscription:
            {{ inscriptionDate }}
          </div>
          <div><v-icon>mdi-tie</v-icon> Role: {{ role }}</div>
        </v-col>
        <v-col cols="12" md="6">
          <span class="d-md-inline-flex">
            <v-btn
              v-if="!user.is_teacher"
              class="mx-2 my-2"
              @click="swapTeacher()"
              >Passer tuteur
            </v-btn>
            <v-btn v-else class="mx-2 my-2" @click="swapTeacher()">
              Enlever tuteur
            </v-btn>
            <v-btn
              v-if="!user.is_admin"
              @click="dialog_activated = true"
              class="mx-2 my-2"
              >Passer administrateur</v-btn
            >
            <v-btn v-else @click="dialog_activated = true" class="mx-2 my-2"
              >Enlever administrateur</v-btn
            >
            <v-dialog v-model="dialog_activated" class="my-0" width="600">
              <DialogCodeVerification
                @close="dialog_activated = false"
                :user="this.user"
              ></DialogCodeVerification>
            </v-dialog>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import dayjs from "dayjs";
import DialogCodeVerification from "../components/DialogCodeVerificationSwapAdmin.vue";

export default {
  name: "CardMember",
  props: {
    user: Object,
  },
  data: () => ({
    dialog_activated: false,
  }),
  components: {
    DialogCodeVerification,
  },
  methods: {
    detail: function () {
      this.$router.push("member/" + this.user._id);
    },
    swapTeacher: function () {
      let payload = {
        _id: this.user._id,
        is_teacher: !this.user.is_teacher,
      };

      this.$store.dispatch("admin/modifyUser", payload);
    },
    swapAdmin: function () {
      let payload = {
        _id: this.user._id,
        is_admin: !this.user.is_admin,
      };

      this.$store.dispatch("admin/modifyUser", payload);
    },
  },
  computed: {
    inscriptionDate: function () {
      return dayjs(this.user.created_at).format("DD/MM/YYYY HH:mm");
    },
    role: function () {
      if (this.user.is_admin) {
        return "Administrateur";
      } else if (this.user.is_teacher) {
        return "Tuteur";
      } else {
        return "Etudiant";
      }
    },
  },
};
</script>
