<template>
  <v-sheet
    elevation="2"
    class="mx-4 my-4 mb-2 rounded-lg"
    @click="dialog_edit = true"
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <div class="my-1">
            <v-icon class="mr-1">{{
              material.is_displayed ? "mdi-eye" : "mdi-eye-off"
            }}</v-icon
            >Visibilité:
            {{
              material.is_displayed
                ? "La matière est visible"
                : "La matière n'est pas visible"
            }}
          </div>
          <div class="my-1">
            <v-icon class="mr-1">mdi-school</v-icon>Ecole: {{ material.school }}
          </div>
          <div class="my-1">
            <v-icon class="mr-1">mdi-cake</v-icon>Année: {{ material.year }}
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="my-1">
            <v-icon class="mr-1">mdi-calendar</v-icon>Semestre:
            {{ material.semester }}
          </div>
          <div class="my-1">
            <v-icon class="mr-1">mdi-book</v-icon>Module:
            {{ material.module }}
          </div>
          <div class="my-1">
            <v-icon class="mr-1">mdi-pencil</v-icon>Nom: {{ material.name }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog_edit" width="600">
      <DialogMaterial
        :src_material="material"
        :create="false"
        :_id="material._id"
        @close="dialog_edit = false"
      />
    </v-dialog>
  </v-sheet>
</template>

<script>
import DialogMaterial from "./DialogMaterial.vue";

export default {
  name: "CardMember",
  components: {
    DialogMaterial,
  },
  props: {
    material: Object,
  },
  methods: {},
  data: () => ({
    dialog_edit: false,
  }),
};
</script>
