<template>
  <v-card class="py-2">
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <h2
      weight="200"
      class="mt-6 d-flex font-weight-regular justify-center align-center"
    >
      Modifier les informations
    </h2>
    <v-divider light color="#EF7D00" class="mt-4 mb-6 mx-4"></v-divider>
    <FirstLogForm
      :inputUser="user"
      :firstLogStudent="true"
      :adminMod="adminMod"
    />
    <ValidateErrorButtons
      @successClicked="validate()"
      @errorClicked="problem()"
    >
      <template v-slot:error>SIGNALER UNE ERREUR</template>
      <template v-slot:success>VALIDER</template>
    </ValidateErrorButtons>
  </v-card>
</template>

<script>
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import FirstLogForm from "./FirstLogForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "DialogEditUser",
  components: {
    FirstLogForm,
    ValidateErrorButtons,
  },
  props: {
    user: Object,
    adminMod: Boolean,
  },
  data: () => ({}),
  methods: {
    problem: function () {
      //TODO: Add popup
      this.$swal.fire({
        icon: "error",
        title: "Un problème ?",
        text: "Code 65",
      });
    },
    validate: function () {
      if (this.adminMod) {
        this.$store
          .dispatch("admin/modifyUser", {
            _id: this.specificUser._id,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            year: this.user.year,
            school: this.user.school,
            dontModify: true,
          })
          .then(() => {
            this.$emit("close");
          });
      } else {
        this.$store
          .dispatch("admin/modifyUser", {
            _id: this.currentUser._id,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            year: this.user.year,
            school: this.user.school,
            dontModify: true,
          })
          .then(() => {
            this.$emit("close");
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
    }),
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
