<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col class="d-none d-md-flex"> </v-col>
      <v-col>
        <v-img
          :src="require('../assets/LogoLeoLearning.png')"
          class="mb-5"
          contain
          height="200"
        />
        <v-card class="card" elevation="2">
          <v-form v-on:submit.prevent="submit()" ref="form">
            <div class="mx-10">
              <p class="pt-6 text-h6">CONNEXION ADMINISTRATEUR</p>
              <v-text-field
                type="email"
                label="Email"
                placeholder="Email"
                :rules="emailRules"
                v-model="payload.email"
              ></v-text-field>
              <v-text-field
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                :rules="passwordRules"
                v-model="payload.password"
              ></v-text-field>
              <v-btn elevation="2" type="submit" color="primary" class="my-5"
                >Connexion</v-btn
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col class="d-none d-md-flex"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  props: {},
  data: () => ({
    payload: {
      email: "",
      password: "",
      connection_type: "password",
    },
    emailRules: [(v) => !!v || "L'email est requis"],
    passwordRules: [(v) => !!v || "Le mot de passe est requis"],
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("user/connectPassword", this.payload);
      }
    },
  },
};
</script>
