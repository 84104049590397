import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NewModifyCourse from "../views/NewModifyCourse.vue";
import CreateCourse from "../views/CreateCourse.vue";
import StudentWelcome from "../views/StudentWelcome.vue";
import TeacherWelcome from "../views/TeacherWelcome.vue";
import Login from "../views/Login.vue";
import About from "../views/About.vue";
import Verify from "../views/Verify";
import Schedule from "../views/Schedule";
import Profile from "../views/ProfilePage";
import Admin from "../views/Admin";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/modify-course/:id",
    name: "ModifyCourse",
    component: NewModifyCourse,
  },
  {
    path: "/backdoor",
    name: "Backdoor",
    component: Login,
  },
  {
    path: "/student-welcome",
    name: "StudentWelcome",
    component: StudentWelcome,
  },
  {
    path: "/teacher-welcome",
    name: "TeacherWelcome",
    component: TeacherWelcome,
  },
  {
    path: "/createcourse",
    name: "CreateCourse",
    component: CreateCourse,
  },
  {
    path: "/createcourse/:memberid",
    name: "CreateCourseAdmin",
    component: CreateCourse,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
  },
  {
    path: "/edt",
    name: "Schedule",
    component: Schedule,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/member/:memberid",
    name: "Member",
    component: Profile,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters["user/loginState"]) {
    if (to.path == "/") {
      next("/profile");
    } else {
      next();
    }
  } else {
    if (
      to.path == "/" ||
      to.path == "/backdoor" ||
      to.path == "/verify" ||
      to.path == "/about"
    ) {
      next();
    } else {
      next("/");
    }
  }
});

export default router;
