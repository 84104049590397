<template>
  <v-card>
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >

    <v-card-title class="text-h5">
      Modification de la localisation
    </v-card-title>

    <v-alert type="warning" icon="mdi-information" class="white--text mx-8">
      Attention tout changement enverra un email aux tutorés</v-alert
    >

    <v-card-text class="mt-2">
      <div>
        <p>Localisation voulue:</p>
        <div class="text-left d-flex mt-n2">
          <div class="d-flex ml-n2">
            <v-radio-group v-model="new_format">
              <v-radio
                v-for="location in possible_format"
                :key="location"
                :value="location"
                class="mx-2"
              >
                <template v-slot:label>
                  <span
                    ><v-icon>{{ icon_dict[location] }}</v-icon>
                    {{ location }}</span
                  >
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
      <div v-if="new_format == 'Présentiel' || new_format == 'Hybride'">
        <p>Salle:</p>
        <v-text-field
          v-model="new_room"
          label="Salle"
          prepend-icon="mdi-door"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-divider></v-divider>
    <v-container>
      <v-row class="text-center">
        <v-col cols="6"
          ><v-btn
            v-on:click.stop="$emit('close')"
            outlined
            elevation="2"
            color="error"
          >
            <v-icon>mdi-close</v-icon> Fermer</v-btn
          ></v-col
        >
        <v-col cols="6">
          <v-btn color="success" elevation="2" width="" @click="edit_room()">
            <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "DialogRoom",
  props: {
    course: Object,
    adminMod: Boolean,
  },
  data: () => ({
    possible_format: ["En ligne", "Présentiel", "Hybride"],

    new_format: "",
    new_room: "",

    icon_dict: {
      "En ligne": "mdi-laptop",
      Présentiel: "mdi-domain",
      Hybride: "mdi-robot",
    },
  }),
  methods: {
    edit_room() {
      if (this.new_format != "") {
        //Call API
        let data = {
          course_id: this.course._id || this.course,
          format: this.new_format,
        };
        if (this.new_room != "") {
          data.room = this.new_room;
        }
        this.$store
          .dispatch("courses/patchCourse", data)
          .then((course) => {
            this.$store.dispatch("courses/course", course);
            this.$emit("close");
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.new_format = this.course.format;
    this.new_room = this.course.room;
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
