<template>
  <div>
    <div class="mx-4">
      <v-container>
        <v-row class="mt-1">
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="name"
              label="Rechercher"
              prepend-icon="mdi-magnify"
              placeholder="Rechercher"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="mt-n2">
            <v-checkbox
              class="d-md-inline-flex mr-4 mb-n4"
              label="ESILV"
              value="ESILV"
              v-model="schools"
            ></v-checkbox>
            <v-checkbox
              class="d-md-inline-flex mr-4 mb-n4"
              label="EMLV"
              value="EMLV"
              v-model="schools"
            ></v-checkbox>
            <v-checkbox
              class="d-md-inline-flex mb-n4"
              label="IIM"
              value="IIM"
              v-model="schools"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row>
          <v-col class="mt-n4" cols="4"
            ><v-btn color="primary" @click="dialog_create_material = true">
              <v-icon class="mr-1 ml-n1">mdi-plus</v-icon> Créer une
              matière</v-btn
            ></v-col
          >
        </v-row>
      </v-container>
      <v-dialog v-model="dialog_create_material" width="600">
        <DialogMaterial
          :src_material="{}"
          :create="true"
          :_id="''"
          @close="dialog_create_material = false"
        />
      </v-dialog>
    </div>
    <div class="overflow-y-auto" style="height: 500px">
      <CardMaterial
        class="mx-4 my-4"
        v-for="material in filteredMaterial"
        :key="material._id"
        :material="material"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardMaterial from "./CardMaterial.vue";
import DialogMaterial from "./DialogMaterial.vue";

export default {
  name: "MaterialViewer",
  components: {
    CardMaterial,
    DialogMaterial,
  },
  data() {
    return {
      scrollInvoked: 0,
      menu: false,

      schools: ["ESILV", "EMLV", "IIM"],
      name: "",

      dialog_create_material: false,

      date: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allMaterials: "materials/getMaterials",
    }),
    filteredMaterial: function () {
      let res = this.allMaterials;
      res = res.filter((material) => {
        return this.complyWithFilter(material);
      });
      return res;
    },
  },
  methods: {
    remove(item) {
      const index = this.filteredstatus.indexOf(item);
      if (index >= 0) this.filteredstatus.splice(index, 1);
    },
    onScroll() {
      this.scrollInvoked++;
    },
    redirectMaterial(id) {
      this.$router.push("/modifycourse/" + id);
    },
    complyWithFilter(material) {
      //Name filter
      if (this.name != null && this.name != "") {
        let materialName = material.name.toLowerCase();
        if (!materialName.includes(this.name.toLowerCase())) {
          return false;
        }
      }

      //School filter
      if (this.schools.length > 0) {
        if (!this.schools.includes(material.school)) {
          return false;
        }
      }

      return true;
    },
  },
  beforeMount: function () {
    this.$store.dispatch("materials/materials", {
      limit: 10000,
      page: 1,
      is_displayed: "all",
    });
  },
};
</script>
