<template>
  <v-card v-if="application.status == 'En attente'">
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <v-form ref="form">
      <v-card-title class="text-h5">
        Modification de mon inscription
      </v-card-title>

      <v-alert type="warning" icon="mdi-information" class="white--text mx-8">
        Votre inscription est en attente de confirmation</v-alert
      >

      <v-card-text class="mt-2">
        <v-icon dense class="mr-1 mb-1">mdi-clock-outline</v-icon> Cours de
        {{ formatted_start_hour }} à {{ formatted_end_hour }} le
        {{ formatted_date }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-2">
        <p>Matière(s) où tu voudrais avoir cours</p>
        <v-autocomplete
          :items="proper_material"
          class="mt-2"
          item-text="name"
          item-value="_id"
          prepend-icon="mdi-book"
          multiple
          large
          required
          dense
          return-object
          placeholder="Matière(s) où tu voudrais avoir cours"
          :rules="material_rule"
          v-model="new_materials"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              class="mx-1 my-1"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-2">
        <p>Localisation voulue</p>
        <div class="text-left d-flex mt-n2">
          <div class="d-flex ml-n2">
            <v-checkbox
              v-for="location in application.course.format_preference"
              v-model="new_format"
              :key="location"
              :label="location"
              :value="location"
              :rules="checkbox_rule"
              :prepend-icon="icon_dict[location]"
              class="mx-2"
            ></v-checkbox>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <p>Description pour le tuteur</p>
        <v-textarea
          class="mt-n2"
          v-model="new_description"
          prepend-icon="mdi-comment"
          rows="2"
          :rules="comment_rule"
          placeholder="Description pour le tuteur"
          required
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>
      <v-container>
        <v-row class="text-center">
          <v-col cols="6"
            ><v-btn @click="delete_application()" elevation="2" color="error">
              <v-icon>mdi-delete</v-icon> Supprimer</v-btn
            ></v-col
          >
          <v-col cols="6">
            <v-btn
              color="success"
              elevation="2"
              width=""
              @click="edit_not_confirmed()"
            >
              <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
  <v-card
    v-else-if="
      application.status == 'Acceptée' &&
      application.course.status == 'Confirmé'
    "
  >
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <v-form ref="form">
      <v-card-title class="text-h5">
        Modification de mon inscription
      </v-card-title>

      <v-alert type="success" icon="mdi-information" class="white--text mx-8">
        Votre inscription a été validé</v-alert
      >

      <v-card-text class="mt-2">
        <v-icon dense class="mr-1 mb-1">mdi-clock-outline</v-icon> Cours de
        {{ formatted_start_hour }} à {{ formatted_end_hour }} le
        {{ formatted_date }}
        <br />
        <v-icon dense class="mr-1 mb-1">mdi-account</v-icon> Tuteur:
        {{ application.course.teacher.firstname }}
        {{ application.course.teacher.lastname }} |
        {{ application.course.teacher.school }} -
        {{ application.course.teacher.year }}
        <span v-if="adminMod">
          <br />
          <v-icon dense class="mr-1 mb-1">mdi-arrow-right</v-icon>
          <router-link :to="'/modify-course/' + application.course._id"
            >Vers le cours</router-link
          >
        </span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-2">
        <p>Matière où tu auras cours</p>
        <v-autocomplete
          :items="material"
          class="mt-2"
          item-text="name"
          item-value="_id"
          prepend-icon="mdi-book"
          multiple
          large
          disabled
          required
          dense
          placeholder="Matière où tu auras cours"
          v-model="material"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              class="mx-1 my-1"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-2">
        <p>
          Localisation définitive
          <span
            v-if="
              application.course.format == 'Présentiel' &&
              application.course.room == ''
            "
          >
            - La salle te sera communiqué bientôt !
          </span>
          <span
            v-else-if="
              application.course.format == 'Présentiel' &&
              application.course.room != ''
            "
          >
            - {{ application.course.room }}
          </span>
          <span
            v-else-if="
              application.course.format == 'Hybride' &&
              application.course.room == ''
            "
          >
            - La salle te sera communiqué bientôt / sur Discord
          </span>
          <span
            v-else-if="
              application.course.format == 'Hybride' &&
              application.course.room != ''
            "
          >
            - {{ application.course.room }} ou sur Discord
          </span>
        </p>
        <div class="text-left d-flex mt-n2">
          <div class="d-flex ml-n2">
            <v-checkbox
              v-for="location in format"
              disabled
              v-model="format"
              :key="location"
              :label="location"
              :value="location"
              :prepend-icon="icon_dict[location]"
              class="mx-2"
            ></v-checkbox>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <p>Description pour le tuteur</p>
        <v-textarea
          class="mt-n2"
          v-model="new_description"
          prepend-icon="mdi-comment"
          rows="2"
          :rules="comment_rule"
          placeholder="Description pour le tuteur"
          required
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>
      <v-container>
        <v-row class="text-center">
          <v-col cols="6"
            ><v-btn @click="delete_application()" elevation="2" color="error">
              <v-icon>mdi-delete</v-icon> Supprimer</v-btn
            ></v-col
          >
          <v-col cols="6">
            <v-btn
              color="success"
              elevation="2"
              width=""
              @click="edit_confirmed()"
            >
              <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
  <v-card
    v-else-if="
      application.status == 'Acceptée' &&
      (application.course.status == 'En cours' ||
        application.course.status == 'Terminé')
    "
  >
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <v-form ref="form">
      <v-card-title class="text-h5"> Retour sur le cours </v-card-title>

      <v-alert type="success" icon="mdi-information" class="white--text mx-8">
        Le cours c'est terminé</v-alert
      >

      <v-card-text class="mt-2">
        <v-icon dense class="mr-1 mb-1">mdi-clock-outline</v-icon> Le cours
        était de {{ formatted_start_hour }} à {{ formatted_end_hour }} le
        {{ formatted_date }}
        <br />
        <v-icon dense class="mr-1 mb-1">mdi-account</v-icon> Tuteur:
        {{ application.course.teacher.firstname }}
        {{ application.course.teacher.lastname }} |
        {{ application.course.teacher.school }} -
        {{ application.course.teacher.year }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-n2 mb-n2">
        <p>Matière où tu auras cours</p>
        <v-autocomplete
          :items="material"
          class="mt-2"
          item-text="name"
          item-value="_id"
          prepend-icon="mdi-book"
          multiple
          large
          disabled
          required
          dense
          placeholder="Matière où tu auras cours"
          v-model="material"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              class="mx-1 my-1"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="mt-n2 mb-n2">
        <p>Localisation définitive</p>
        <div class="text-left d-flex mt-n2">
          <div class="d-flex ml-n2">
            <v-checkbox
              v-for="location in format"
              disabled
              v-model="format"
              :key="location"
              :label="location"
              :value="location"
              :prepend-icon="icon_dict[location]"
              class="mx-2"
            ></v-checkbox>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="mt-n2 mb-n2">
        <p>Description pour le tuteur</p>
        <v-textarea
          class="mt-n2"
          v-model="new_description"
          prepend-icon="mdi-comment"
          rows="2"
          disabled
          placeholder="Description pour le tuteur"
          required
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="mt-n2 mb-n2">
        <p>Feedback pour le tuteur</p>
        <v-textarea
          class="mt-n2"
          v-model="new_feedback"
          prepend-icon="mdi-comment"
          rows="2"
          placeholder="Feedback pour le tuteur"
          required
        ></v-textarea>
      </v-card-text>

      <v-divider></v-divider>
      <v-container>
        <v-row class="text-center">
          <v-col cols="6"
            ><v-btn elevation="2" disabled color="error">
              <v-icon>mdi-delete</v-icon> Supprimer</v-btn
            ></v-col
          >
          <v-col cols="6">
            <v-btn
              color="success"
              elevation="2"
              width=""
              @click="edit_feedback()"
            >
              <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "DialogApplication",
  props: {
    application: Object,
    adminMod: Boolean,
  },
  data: () => ({
    new_materials: [],
    new_format: [],
    new_description: "",
    new_feedback: "",

    icon_dict: {
      "En ligne": "mdi-laptop",
      Présentiel: "mdi-domain",
      Hybride: "mdi-robot",
    },
  }),
  methods: {
    remove(item) {
      const index = this.new_materials
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.new_materials.splice(index, 1);
    },
    edit_not_confirmed() {
      if (this.$refs.form.validate()) {
        //Call API
        this.$store
          .dispatch("user/patchApplication", {
            _id: this.application._id,
            materials: this.new_materials.map((mat) => mat._id),
            format_preference: this.new_format,
            description: this.new_description,
          })
          .then((application) => {
            let index = this.user.student.applications.findIndex(
              (app) => app._id == application._id
            );

            this.user.student.applications.splice(index, 1, application);
            if (this.adminMod) {
              this.$store.dispatch("admin/setSpecificUser", this.user);
            } else {
              this.$store.dispatch("user/user", this.user);
            }
            this.$emit("close");
          })
          .catch(() => {});
      }
    },
    edit_confirmed() {
      if (this.$refs.form.validate()) {
        //Call API
        this.$store
          .dispatch("user/patchApplication", {
            _id: this.application._id,
            description: this.new_description,
          })
          .then((application) => {
            let index = this.user.student.applications.findIndex(
              (app) => app._id == application._id
            );

            this.user.student.applications.splice(index, 1, application);
            if (this.adminMod) {
              this.$store.dispatch("admin/setSpecificUser", this.user);
            } else {
              this.$store.dispatch("user/user", this.user);
            }
            this.$emit("close");
          })
          .catch(() => {});
      }
    },
    edit_feedback() {
      if (this.$refs.form.validate()) {
        //Call API
        this.$store
          .dispatch("user/patchApplication", {
            _id: this.application._id,
            feedback: this.new_feedback,
          })
          .then((application) => {
            let index = this.user.student.applications.findIndex(
              (app) => app._id == application._id
            );

            this.user.student.applications.splice(index, 1, application);
            if (this.adminMod) {
              this.$store.dispatch("admin/setSpecificUser", this.user);
            } else {
              this.$store.dispatch("user/user", this.user);
            }
            this.$emit("close");
          })
          .catch(() => {});
      }
    },
    delete_application() {
      this.$store
        .dispatch("user/deleteApplication", this.application._id)
        .then(() => {
          this.$emit("close");
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
    }),
    formatted_start_hour: function () {
      return dayjs(this.application.course.start_date)
        .format("HH:mm")
        .replace(":", "h");
    },
    formatted_end_hour: function () {
      return dayjs(this.application.course.end_date)
        .format("HH:mm")
        .replace(":", "h");
    },
    formatted_date: function () {
      return dayjs(this.application.course.start_date).format("DD/MM/YYYY");
    },
    material_rule: function () {
      return [
        this.new_materials.length > 0 ||
          "Au moins une des matières doit être sélectionné",
      ];
    },
    checkbox_rule: function () {
      return [
        this.new_format.length > 0 ||
          "Au moins un des champs doit être sélectionné",
      ];
    },
    comment_rule: function () {
      return [
        this.new_description != "" || "Description ne doit pas être vide",
        this.new_description.length > 20 ||
          "Expliquez longement votre problème",
      ];
    },
    user: function () {
      if (this.adminMod) {
        return this.specificUser;
      } else {
        return this.currentUser;
      }
    },
    material: function () {
      return [this.application.course.material];
    },
    format: function () {
      return [this.application.course.format];
    },
    proper_material: function () {
      return this.application.course.materials.filter((material) => {
        if (this.user.is_admin) {
          return true;
        }

        return (
          material.year == this.user.year && material.school == this.user.school
        );
      });
    },
  },
  created() {
    this.new_materials = this.application.materials;
    this.new_format = this.application.format_preference;
    this.new_description = this.application.description;
    this.new_feedback = this.application.feedback;
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
