<template>
  <div>
    <div class="mx-4">
      <div class="d-inline-flex" v-if="user.is_teacher">
        <v-checkbox
          class="d-inline-flex mr-4"
          label="Tuteur"
          value="teacher"
          v-model="filters.filter1"
        ></v-checkbox>
        <v-checkbox
          class="d-inline-flex"
          label="Étudiant"
          value="student"
          v-model="filters.filter1"
        ></v-checkbox>
      </div>
      <v-divider
        class="d-none d-sm-inline-flex mx-4"
        v-if="user.is_teacher"
        vertical
        style="height: 30px; width: 204px"
      ></v-divider>
      <br class="d-sm-none" />
      <div class="d-inline-flex">
        <v-checkbox
          class="d-inline-flex mr-3"
          label="À venir"
          value="upcoming"
          v-model="filters.filter2"
        ></v-checkbox>
        <v-checkbox
          class="d-inline-flex"
          label="Passé"
          value="passed"
          v-model="filters.filter2"
        ></v-checkbox>
      </div>
    </div>

    <span class="text-h5 black--text mx-4">Mes cours à venir / passé</span>
    <CourseApplicationWrapper
      :cards="mergeApplicationCourse"
      :filters="filters"
      :adminMod="adminMod"
    />
  </div>
</template>

<script>
import CourseApplicationWrapper from "./CourseApplicationWrapper.vue";

export default {
  name: "CourseApplicationViewer",
  components: {
    CourseApplicationWrapper,
  },
  props: {
    user: Object,
    adminMod: Boolean,
  },
  data: () => ({
    scrollInvoked: 0,
    display1: "student",
    display2: "upcoming",
    filters: {
      filter1: ["student", "teacher"],
      filter2: ["upcoming", "passed"],
    },
  }),
  computed: {
    teacherCourse: function () {
      let res = [];
      if (this.user.is_teacher) {
        res = this.user.teacher.courses.map((course) => {
          return course;
        });
      }
      return res;
    },
    studentApplications: function () {
      let res = this.user.student.applications.map((application) => {
        return application;
      });
      return res;
    },
    mergeApplicationCourse: function () {
      let res = this.user.student.applications;
      if (this.user.is_teacher) {
        res = res.concat(this.user.teacher.courses);
      }

      //Order res
      res.sort((a, b) => {
        let a_date = "";
        if (a.type == "application") {
          a_date = a.course.start_date;
        } else {
          a_date = a.start_date;
        }

        let b_date = "";
        if (b.type == "application") {
          b_date = b.course.start_date;
        } else {
          b_date = b.start_date;
        }

        if (Date.parse(a_date) > Date.parse(b_date)) {
          return -1;
        } else {
          return 1;
        }
      });
      return res;
    },
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
    redirectCourse(id) {
      this.$router.push("/modifycourse/" + id);
    },
  },
};
</script>
