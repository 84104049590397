<template>
  <v-card
    v-if="course.status == 'En attente de confirmation'"
    @click="$router.push('/modify-course/' + course._id)"
    elevation="2"
  >
    <div class="">
      <v-card-title class="wb">
        {{ course.status }}
      </v-card-title>
      <v-card-subtitle class="text-left wb">
        <span v-if="isAdminMode">
          <v-icon>mdi-account</v-icon>
          Tuteur: {{ course.teacher.firstname }} {{ course.teacher.lastname }}
          <br />
        </span>
        <v-icon>mdi-book</v-icon>
        Matières disponibles:
        {{
          course.materials
            .map((material) => {
              return material.name;
            })
            .join(", ")
        }}
        <br />
        <v-icon>mdi-calendar</v-icon>
        {{ start_date_formated }} ->
        {{ end_date_formated }}
        <br />
        <v-icon>mdi-map-marker</v-icon> Localisation provisoire:
        {{ course.format_preference.join(", ") }}
      </v-card-subtitle>
      <v-card-text>
        <span class="text-body-1">Nombre de tutoré inscrit: </span>
        {{ course.applications.length }}
      </v-card-text>
    </div>
  </v-card>
  <v-card
    v-else-if="
      course.status == 'Confirmé' ||
      course.status == 'En cours' ||
      course.status == 'Terminé'
    "
    elevation="2"
    @click="$router.push('/modify-course/' + course._id)"
  >
    <div class="">
      <v-card-title class="wb">
        {{ course.status }}
      </v-card-title>
      <v-card-subtitle class="text-left wb">
        <span v-if="isAdminMode">
          <v-icon>mdi-account</v-icon>
          Tuteur: {{ course.teacher.firstname }} {{ course.teacher.lastname }}
          <br />
        </span>
        <v-icon>mdi-book</v-icon>
        Matières sélectionné:
        {{ course.material.name }}
        <br />
        <v-icon>mdi-calendar</v-icon>
        {{ start_date_formated }} ->
        {{ end_date_formated }}
        <br />
        <v-icon>mdi-map-marker</v-icon> Localisation: {{ course.format }} |
        <span v-if="course.format == 'Présentiel'">
          <span v-if="course.room != ''"> Salle: {{ course.room }}</span>
          <span v-else-if="!isAdminMode">
            La salle te sera communiqué bientôt !
          </span>
          <span v-else style="color: red">BESOIN D'UNE SALLE </span>
        </span>
        <span v-else-if="course.format == 'En ligne'"> DISCORD </span>
        <span v-else-if="course.format == 'Hybride'">
          <span v-if="course.room != ''">
            Salle: {{ course.room }} / DISCORD
          </span>
          <span v-else-if="!isAdminMode">
            La salle te sera communiqué bientôt ! / DISCORD
          </span>
          <span v-else style="color: red">BESOIN D'UNE SALLE / DISCORD</span>
        </span>
      </v-card-subtitle>
      <v-card-text>
        <span class="text-body-1">
          Nombre de sélectionné: {{ course.selected.length }} | Nombre de
          refusé: {{ course.refused.length }}
        </span>
        <span class="text-body-1" v-if="course.status == 'Terminé'">
          | Nombre d'absent: {{ course.missing.length }}
        </span>
      </v-card-text>
    </div>
  </v-card>

  <v-card
    v-else-if="course.status == 'Annulé'"
    elevation="2"
    @click="$router.push('/modify-course/' + course._id)"
  >
    <div class="">
      <v-card-title class="wb">
        <span v-if="course.is_cancel_after_selection">
          {{ course.status }} par le tuteur/admin</span
        >
        <span v-else>
          {{ course.status }}
        </span>
      </v-card-title>
      <v-card-subtitle class="text-left wb">
        <span v-if="isAdminMode">
          <v-icon>mdi-account</v-icon>
          Tuteur: {{ course.teacher.firstname }} {{ course.teacher.lastname }}
          <br />
        </span>

        <v-icon>mdi-calendar</v-icon>
        {{ start_date_formated }} ->
        {{ end_date_formated }}
      </v-card-subtitle>
      <v-card-text>
        <span v-if="course.is_cancel_after_selection">
          Le cours à été annulé après la séléction ou par la séléction
        </span>
        <span v-else> Le cours à été annulé avant la séléction </span>
      </v-card-text>
    </div>
  </v-card>
  <v-card v-else elevation="2"> {{ course }} </v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CardCourse",
  props: {
    course: Object,
    isAdminMode: Boolean,
  },
  computed: {
    start_date_formated: function () {
      return dayjs(this.course.start_date).format("DD-MM-YYYY HH:mm");
    },
    end_date_formated: function () {
      return dayjs(this.course.end_date).format("HH:mm");
    },
  },
};
</script>

<style scoped>
.divider-h {
  border-width: 2px;
}

.wb {
  word-break: normal;
}
</style>
