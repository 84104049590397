<template>
  <v-card style="height: 125px">
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >
    <v-form ref="form">
      <v-card-title v-if="missing" class="text-h5">
        Mettre absent
      </v-card-title>
      <v-card-title v-else class="text-h5"> Mettre présent </v-card-title>

      <div class="text-center">
        <v-btn color="success" elevation="2" width="" @click="validate()">
          <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
  name: "DialogSwapMissingApplication",
  props: {
    application: Object,
    adminMod: Boolean,
    missing: Boolean,
  },
  data: () => ({}),
  // computed: {
  //   ...mapGetters({
  //     currentUser: "user/getUser",
  //     specificUser: "admin/getSpecificUser",
  //   }),
  //   user: function () {
  //     if (this.adminMod) {
  //       return this.specificUser;
  //     } else {
  //       return this.currentUser;
  //     }
  //   },
  // },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        let course_id = this.application.course._id || this.application.course;
        //Call API
        this.$store
          .dispatch("courses/swapMissing", {
            course_id: course_id,
            application_id: this.application._id,
          })
          .then((course) => {
            this.$store.dispatch("courses/course", course);

            // let index = this.user.student.applications.findIndex(
            //   (app) => app._id == course_id
            // );

            //TODO: Return application when doing swap

            // this.user.student.applications.splice(index, 1, course);
            // if (this.adminMod) {
            //   this.$store.dispatch("admin/setSpecificUser", this.user);
            // } else {
            //   this.$store.dispatch("user/user", this.user);
            // }

            this.$emit("close");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
