//import $axios from "../plugins/axios";
import Vue from "vue";

const topEndMessage = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Vue.swal.stopTimer);
    toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
  },
};

const errSwal = function (code) {
  return {
    icon: "error",
    title: "Oops...",
    text: "Quelque chose c'est mal passé 🤔",
    footer:
      "Si le problème persiste contacte LéoLearning avec le code: " + code,
  };
};

export default {
  namespaced: true,
  state: {
    allUsers: [],
    allCourses: [],
    specificUser: {},
  },
  mutations: {
    LOGINSTATE(state, loginState) {
      state.loginState = loginState;
    },
    ALLUSERS(state, allUsers) {
      state.allUsers = allUsers;
    },
    ALLCOURSES(state, allCourses) {
      state.allCourses = allCourses;
    },
    SPECIFICUSER(state, specificUser) {
      state.specificUser = specificUser;
    },
  },
  actions: {
    setAllUser(context, value) {
      context.commit("ALLUSERS", value);
    },
    setSpecificUser(context, value) {
      context.commit("SPECIFICUSER", value);
    },
    importAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("users")
          .then((response) => {
            commit("ALLUSERS", response.data.results);
            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(12));
          });
      });
    },
    importAllCourses({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("courses/all")
          .then((response) => {
            commit("ALLCOURSES", response.data.results);
            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(18));
          });
      });
    },
    getUserById({ commit }, value) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("users/" + value)
          .then((response) => {
            commit("SPECIFICUSER", response.data.user);
            resolve(response.data.user);
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(13));
          });
      });
    },
    createCourse({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("courses", payload)
          .then((response) => {
            let user = state.specificUser;
            user.teacher.courses.push(response.data.course);
            commit("SPECIFICUSER", user);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Cours créé avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(15));
          });
      });
    },
    modifyUser({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("users/" + payload._id, payload)
          .then((response) => {
            if (payload.dontModify) {
              Vue.swal.mixin(topEndMessage).fire({
                icon: "success",
                title: "L'utilisateur a été modifié avec succès",
              });
              resolve();
              return;
            }
            let users = state.allUsers;
            //Find user
            let index = users.findIndex((user) => {
              return user._id == payload._id;
            });

            if (index == -1) {
              reject("User not found");
              return;
            }

            users.splice(index, 1, response.data.user);

            commit("ALLUSERS", users);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "L'utilisateur a été modifié avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(15));
          });
      });
    },
  },
  getters: {
    getAllUsers(state) {
      return state.allUsers;
    },
    getAllCourses(state) {
      return state.allCourses;
    },
    getSpecificUser(state) {
      return state.specificUser;
    },
  },
};
