<template>
  <div>
    <div class="mx-4">
      <div class="d-md-inline-flex mb-n4 mr-2">
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="Administrateur"
          value="admin"
          v-model="filters"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="Tuteur"
          value="teacher"
          v-model="filters"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mb-n4"
          label="Étudiant"
          value="student"
          v-model="filters"
        ></v-checkbox>
      </div>
      <v-divider vertical style="height: 30px" class="mr-1"></v-divider>
      <div class="d-md-inline-flex mb-n4 mr-2">
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="ESILV"
          value="ESILV"
          v-model="schools"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="EMLV"
          value="EMLV"
          v-model="schools"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mb-n4"
          label="IIM"
          value="IIM"
          v-model="schools"
        ></v-checkbox>
      </div>
      <v-divider vertical style="height: 30px" class="mr-1"></v-divider>
      <div class="d-md-inline-flex mb-n4">
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="A1"
          value="A1"
          v-model="years"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="A2"
          value="A2"
          v-model="years"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mb-n4"
          label="A3"
          value="A3"
          v-model="years"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mr-4 mb-n4"
          label="A4"
          value="A4"
          v-model="years"
        ></v-checkbox>
        <v-checkbox
          class="d-md-inline-flex mb-n4"
          label="A5"
          value="A5"
          v-model="years"
        ></v-checkbox>
      </div>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="name"
            label="Rechercher"
            prepend-icon="mdi-magnify"
            placeholder="Rechercher"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="mt-7">
          <span> Nombre d'étudiant: {{ this.allUsers.length }}</span>
        </v-col>
      </v-row>
    </div>
    <div class="overflow-y-auto" style="height: 490px">
      <CardMember v-for="user in filteredUsers" :key="user._id" :user="user" />
    </div>
    <v-row class="py-4">
      <v-col class="d-flex justify-center">
        <v-btn
          @click="changePage('start')"
          :disabled="this.currentPage <= 1"
          rounded
          fab
          small
          class="mr-3"
        >
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
        <v-btn
          @click="changePage(-1)"
          :disabled="this.currentPage <= 1"
          rounded
          fab
          small
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <p class="text-center mx-6 mt-2 font-weight-regular text-h5">
          Page n°{{ this.currentPage }} - de
          {{ (this.currentPage - 1) * this.limit + 1 }} à
          {{
            this.currentPage == Math.ceil(this.allUsers.length / this.limit)
              ? this.allUsers.length
              : this.currentPage * this.limit
          }}
        </p>
        <v-btn
          @click="changePage(1)"
          :disabled="this.currentPage >= this.limit * this.allUsers.length"
          rounded
          fab
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          @click="changePage('end')"
          :disabled="this.currentPage >= this.limit * this.allUsers.length"
          rounded
          fab
          small
          class="ml-3"
        >
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardMember from "../components/CardMember.vue";

export default {
  name: "CourseApplicationViewer",
  components: {
    CardMember,
  },
  data: () => ({
    scrollInvoked: 0,

    filters: ["student", "teacher", "admin"],
    schools: ["ESILV", "EMLV", "IIM"],
    years: ["A1", "A2", "A3", "A4", "A5"],
    name: "",

    currentPage: 1,
    limit: 49,
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allUsers: "admin/getAllUsers",
    }),
    filteredUsers: function () {
      if (this.filters.length != 0) {
        let res;
        if (this.name == null || this.name == "") {
          res = this.allUsers
            .slice(
              (this.currentPage - 1) * this.limit,
              this.currentPage * this.limit
            )
            .filter((user) => {
              if (!this.schools.includes(user.school)) {
                return false;
              }

              if (!this.years.includes(user.year)) {
                return false;
              }

              if (user.is_admin && !this.filters.includes("admin")) {
                return false;
              } else if (user.is_admin && this.filters.includes("admin")) {
                return true;
              }

              if (user.is_teacher && !this.filters.includes("teacher")) {
                return false;
              } else if (user.is_teacher && this.filters.includes("teacher")) {
                return true;
              }

              if (user.is_student && !this.filters.includes("student")) {
                return false;
              } else if (user.is_student && this.filters.includes("student")) {
                return true;
              }
            });
        } else {
          res = this.allUsers.filter((user) => {
            if (this.name == null) {
              this.name = "";
            } else {
              if (
                !`${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`.includes(
                  this.name.toLowerCase()
                )
              ) {
                return false;
              }
            }

            if (!this.schools.includes(user.school)) {
              return false;
            }

            if (!this.years.includes(user.year)) {
              return false;
            }

            if (user.is_admin && !this.filters.includes("admin")) {
              return false;
            } else if (user.is_admin && this.filters.includes("admin")) {
              return true;
            }

            if (user.is_teacher && !this.filters.includes("teacher")) {
              return false;
            } else if (user.is_teacher && this.filters.includes("teacher")) {
              return true;
            }

            if (user.is_student && !this.filters.includes("student")) {
              return false;
            } else if (user.is_student && this.filters.includes("student")) {
              return true;
            }
          });
        }
        return res;
      }
      return [];
    },
    studentsName: function () {
      return this.listUsers.map((user) => {
        return user.firstname + " " + user.lastname;
      });
    },
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
    redirectCourse(id) {
      this.$router.push("/modifycourse/" + id);
    },
    changePage(sens) {
      if (sens == "start") {
        this.currentPage = 1;
      } else if (sens == "end") {
        this.currentPage = Math.ceil(this.allUsers.length / this.limit);
      } else {
        this.currentPage += sens;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("admin/importAllUsers", {
      limit: 99,
      page: 1,
    });
  },
};
</script>
