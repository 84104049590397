<template>
  <div class="background">
    <v-container fluid>
      <v-col class="px-lg-12">
        <v-row class="d-flex justify-space-between px-2 py-1">
          <div>
            <BtnAddCourse :user="user" :isAdminMode="false" />
            <v-btn
              v-if="user.is_admin"
              class="mt-4 mr-3"
              color="#E97132"
              large
              @click="dialog_addExam = true"
            >
              <v-icon class="mr-4">mdi-plus</v-icon>
              Ajouter un examen
            </v-btn>
          </div>
          <v-card
            elevation="1"
            large
            :ripple="false"
            class="mt-4 mr-3 pa-2 rounded d-flex align-center localOpeningState"
            @click="dialogModifyOpened = true"
          >
            <div
              class="circle"
              :style="{
                backgroundColor: localOpeningState ? 'green' : 'red',
                cursor: user.is_admin ? 'pointer' : 'default',
              }"
              @click="user.is_admin ? changeLocalOpeningState() : {}"
            ></div>
            <div class="d-flex flex-column ml-1">
              <div>Local {{ localOpeningState ? "ouvert" : "fermé" }}</div>
              <i style="font-size: 12px; margin-top: -5px">E357</i>
            </div>
          </v-card>
        </v-row>
        <v-row class="py-4">
          <v-col class="d-flex justify-center">
            <v-btn
              @click="setWeekToday"
              :disabled="todayWeek"
              rounded
              fab
              small
              class="mr-3"
            >
              <v-icon>mdi-page-first</v-icon>
            </v-btn>
            <v-btn @click="prevWeek" :disabled="todayWeek" rounded fab small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <p
              class="
                text-uppercase text-center
                mx-6
                mt-2
                font-weight-regular
                text-h5
              "
            >
              SEMAINE DU
              {{ selectedWeek.day(1).locale("fr").format("DD MMMM", "fr") }} AU
              {{ selectedWeek.day(7).locale("fr").format("DD MMMM", "fr") }}
            </p>
            <v-btn @click="nextWeek" rounded fab small>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-form class="filters">
          <v-row>
            <v-col class="d-inline-flex" cols="12" xl="6" lg="6">
              <v-autocomplete
                :items="materials"
                item-text="name"
                item-value="_id"
                prepend-icon="mdi-book"
                multiple
                large
                dense
                return-object
                label="Matières où j'ai besoin d'aide"
                v-model="filtered_materials"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    class="mx-1 my-1"
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="d-inline-flex" cols="12" xl="3" lg="5">
              <v-checkbox
                class="mt-2 mx-2"
                v-model="format_checked"
                label="Présentiel"
                value="Présentiel"
                :prepend-icon="icon_dict['Présentiel']"
              ></v-checkbox>
              <v-checkbox
                class="mt-2 mx-2"
                v-model="format_checked"
                label="En ligne"
                value="En ligne"
                :prepend-icon="icon_dict['En ligne']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mb-6">
          <div class="overflow-x-auto d-flex" style="width: 100%">
            <v-col
              v-for="(day, index) in selectedWeekArray"
              :key="index"
              cols="10"
              sm="7"
              md="5"
              lg="2"
              xl="2"
            >
              <v-sheet
                class="pb-2 rounded-lg grey--text text--darken-1"
                color="grey lighten-3"
                elevation="5"
                height="55vh"
              >
                <p class="text-capitalize text-center pt-2">
                  {{ formatDate(day) }}
                </p>
                <p class="text-center ma-2"></p>
                <v-divider
                  width="90%"
                  class="mx-auto"
                  :class="user.school"
                ></v-divider>
                <div class="overflow-y-auto" style="max-height: 90%">
                  <TimePeriod
                    :course="course"
                    :user="user"
                    :preselected_materials="filtered_materials"
                    :preselected_format="format_checked"
                    v-for="course in mappedDays.get(day)"
                    :key="course._id"
                  />
                </div>
              </v-sheet>
            </v-col>
          </div>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import TimePeriod from "./../components/TimePeriod.vue";
import BtnAddCourse from "./../components/BtnAddCourse.vue";

import dayjs from "dayjs";
import weekYear from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/fr";
import objectSupport from "dayjs/plugin/objectSupport";
import utc from "dayjs/plugin/utc";
// import { filter } from "vue/types/umd";

dayjs.extend(utc);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

export default {
  components: {
    TimePeriod,
    BtnAddCourse,
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      materials: "materials/getMaterials",
      courses: "courses/getCourses",
      localOpeningState: "local/getLocalOpeningState",
    }),
    mappedDays: function () {
      //Key: day format: YYYY-MM-DD // Value: Array with courses
      let map = new Map();

      this.filteredCourses.forEach((item) => {
        var arrayDate = new Date(item.start_date);
        var formattedDay = dayjs(arrayDate).locale("fr").format("YYYY-MM-DD");
        if (map.has(formattedDay)) {
          var sameDateCourse = map.get(formattedDay);
          sameDateCourse.push(item);
          //Order same date push
          sameDateCourse.sort((a, b) => {
            if (new Date(a.start_date) > new Date(b.start_date)) {
              return 1;
            } else {
              return -1;
            }
          });
          map.set(formattedDay, sameDateCourse);
        } else {
          map.set(formattedDay, [item]);
        }
      });
      return map;
    },
    filteredCourses: function () {
      let filtered_courses = this.courses;
      let user_course_id = [];
      if (this.user.is_teacher) {
        user_course_id = this.user.teacher.courses.map((course) => {
          return course._id;
        });
      }

      let user_application_id = this.user.student.applications.map(
        (application) => {
          return application.course._id;
        }
      );

      filtered_courses = filtered_courses.filter((course) => {
        let course_material_ids = course.materials.map((material) => {
          return material._id;
        });

        let oneOfMaterialIncluded = false;
        let isGoodFormat = false;

        if (!this.user.is_admin) {
          if (user_course_id.includes(course._id)) {
            return false;
          }
        }

        if (!this.user.is_admin) {
          if (user_application_id.includes(course._id)) {
            return false;
          }
        }

        if (this.filtered_materials.length != 0) {
          for (let material of this.filtered_materials) {
            if (course_material_ids.includes(material._id)) {
              oneOfMaterialIncluded = true;
              break;
            }
          }
        } else {
          oneOfMaterialIncluded = true;
        }

        if (this.format_checked.length != 0) {
          for (let format of this.format_checked) {
            if (course.format_preference.includes(format)) {
              isGoodFormat = true;
              break;
            }
          }
        } else {
          isGoodFormat = true;
        }

        return oneOfMaterialIncluded && isGoodFormat;
      });

      return filtered_courses;
    },
    todayWeek: function () {
      let today = dayjs();
      return this.returnWeek(today)[0] == this.selectedWeekArray[0];
    },
  },
  data: () => ({
    scroll: 0,

    format_checked: [],
    filtered_materials: [],
    filtered_courses: [],

    materialsFilter: [],
    selectedWeekArray: [],
    selectedWeek: dayjs(),

    icon_dict: {
      "En ligne": "mdi-laptop",
      Présentiel: "mdi-domain",
    },
  }),
  created: function () {
    this.getWeek(this.selectedWeek);
  },
  beforeMount: function () {
    //Get localOpeningState
    this.$store.dispatch("local/localOpeningState");

    if (this.user.is_admin) {
      //Refresh materials, show only student years
      this.$store.dispatch("materials/materials", {
        limit: 10000,
        page: 1,
        is_displayed: true,
      });
      //Get all courses for his school/year
      this.$store.dispatch("courses/courses", {
        limit: 10000,
        page: 1,
      });
    } else if (this.user.is_teacher) {
      this.$store.dispatch("materials/materials", {
        limit: 10000,
        page: 1,
        is_displayed: true,
        school: this.user.school,
      });
      //Get all courses for his school/year
      this.$store.dispatch("courses/courses", {
        limit: 10000,
        page: 1,
        school: this.user.school,
      });
    } else {
      this.$store.dispatch("materials/materials", {
        limit: 10000,
        page: 1,
        is_displayed: true,
        school: this.user.school,
        year: this.user.year,
      });
      //Get all courses for his school/year
      this.$store.dispatch("courses/courses", {
        limit: 10000,
        page: 1,
        school: this.user.school,
        year: this.user.year,
      });
    }
  },
  methods: {
    getWeek(weekDate) {
      this.selectedWeekArray = [];
      for (let i = 1; i < 7; i++) {
        var dayOfTheWeek = weekDate.day(i);
        // Create dayjs object with only the date
        var formattedDay = {
          year: dayOfTheWeek.year(),
          month: dayOfTheWeek.month(),
          day: dayOfTheWeek.date(),
        };
        this.selectedWeekArray.push(
          dayjs(formattedDay).locale("fr").format("YYYY-MM-DD")
        );
      }
    },
    returnWeek(weekDate) {
      let weekArray = [];
      for (let i = 1; i < 7; i++) {
        var dayOfTheWeek = weekDate.day(i);
        // Create dayjs object with only the date
        var formattedDay = {
          year: dayOfTheWeek.year(),
          month: dayOfTheWeek.month(),
          day: dayOfTheWeek.date(),
        };
        weekArray.push(dayjs(formattedDay).locale("fr").format("YYYY-MM-DD"));
      }
      return weekArray;
    },
    setWeekToday() {
      let today = dayjs();
      this.selectedWeek = today;
      this.getWeek(today);
    },
    prevWeek() {
      this.selectedWeek = this.selectedWeek.subtract(1, "week");
      this.getWeek(this.selectedWeek);
    },
    nextWeek() {
      this.selectedWeek = this.selectedWeek.add(1, "week");
      this.getWeek(this.selectedWeek);
    },
    formatDate(day) {
      return dayjs(day, "YYYY-MM-DD").locale("fr").format("dddd DD/MM");
    },

    remove(item) {
      const index = this.filtered_materials
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.filtered_materials.splice(index, 1);
    },
    onScroll() {
      this.scroll++;
    },
    changeLocalOpeningState() {
      this.$store.dispatch("local/changeLocalOpeningState");
    },
  },
};
</script>
<style>
.title {
  width: 500px;
}

.v-tooltip__content {
  z-index: 10 !important;
}
.background {
  background-color: #d6d6d6;
  background-image: url(../assets/bg_white_wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: auto;
}

.localOpeningState {
  cursor: default !important;
  user-select: none !important;
}
.localOpeningState .circle {
  height: 3vh !important;
  aspect-ratio: 1/1 !important;
  border-radius: 100% !important;
  margin-right: 5px !important;
}
</style>
