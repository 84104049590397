//import $axios from "../plugins/axios";
import router from "../router/index";
import Vue from "vue";

const errSwal = function (code) {
  return {
    icon: "error",
    title: "Oops...",
    text: "Quelque chose c'est mal passé 🤔",
    footer:
      "Si le problème persiste contacte LéoLearning avec le code: " + code,
  };
};

const topEndMessage = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Vue.swal.stopTimer);
    toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
  },
};

export default {
  namespaced: true,
  state: {
    loginState: false,
    user: {},
  },
  mutations: {
    LOGINSTATE(state, loginState) {
      state.loginState = loginState;
    },
    USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    loginState(context, value) {
      context.commit("LOGINSTATE", value);
    },
    user(context, value) {
      context.commit("USER", value);
    },
    connectLeoid(context, token) {
      Vue.axios
        .post("auth/", {
          token: token,
          connection_type: "leoid",
        })
        .then((response) => {
          //Save User info
          let user = response.data.user;
          context.commit("USER", user);
          context.commit("LOGINSTATE", true);

          Vue.swal.mixin(topEndMessage).fire({
            icon: "success",
            title: "Connexion réussie",
          });

          if (user.is_first_login) {
            router.push("/student-welcome");
          } else if (user.is_teacher && user.teacher.is_first_login) {
            router.push("/teacher-welcome");
          } else {
            router.push("/profile");
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire(errSwal(3));
          router.push("/");
        });
    },
    connectPassword(context, payload) {
      Vue.axios
        .post("auth/", payload)
        .then((response) => {
          let user = response.data.user;
          context.commit("USER", user);
          context.commit("LOGINSTATE", true);

          Vue.swal.mixin(topEndMessage).fire({
            icon: "success",
            title: "Connexion réussie",
          });

          //Save User info
          if (user.is_first_login) {
            router.push("/student-welcome");
          } else if (user.is_teacher && user.teacher.is_first_login) {
            router.push("/teacher-welcome");
          } else {
            router.push("/profile");
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire(errSwal(2));
        });
      return null;
    },
    disconnect(context) {
      Vue.axios
        .post("auth/disconnect")
        .then(() => {
          context.commit("USER", {});
          context.commit("LOGINSTATE", false);
          router.push("/");
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire(errSwal(1));
        });
    },
    isLoggedIn({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(`auth/isLoggedIn`)
          .then((res) => {
            commit("USER", res.data.user);
            resolve();
          })
          .catch((err) => {
            Vue.swal.fire(errSwal(5));
            reject(err.response.data);
          });
      });
    },
    firstLogin({ state }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch(`users/${state.user._id}/firstLogin`, payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            Vue.swal.fire(errSwal(4));
            reject(err.response.data);
          });
      });
    },
    firstLoginTeacher({ state }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch(`users/${state.user._id}/teacher/firstLogin`)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            Vue.swal.fire(errSwal(5));
            reject(err.response.data);
          });
      });
    },
    patchTeacher(context, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch(`users/${payload._id}/teacher`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            Vue.swal.fire(errSwal(6));
            reject(err.response.data);
          });
      });
    },
    getCourseById(context, id) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("courses/" + id)
          .then((response) => {
            resolve(response.data.course);
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
          });
      });
    },
    createCourse({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("courses", payload)
          .then((response) => {
            let user = state.user;
            user.teacher.courses.push(response.data.course);
            commit("USER", user);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Cours créé avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(7));
          });
      });
    },
    deleteCourse({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        if (payload._id == undefined) {
          reject();
        }
        Vue.axios
          .delete("courses/" + payload._id)
          .then(() => {
            let user = state.user;
            let courses = user.teacher.courses.filter((course) => {
              return course._id != payload._id;
            });

            user.teacher.courses = courses;

            commit("USER", user);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Cours supprimé avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire(errSwal(8));
            reject(err.response.data);
          });
      });
    },
    createApplication({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("applications", payload)
          .then((response) => {
            let user = state.user;
            user.student.applications.push(response.data.application);
            commit("USER", user);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Inscription au cours créée avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(10));
          });
      });
    },
    patchApplication(context, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("applications/" + payload._id, payload)
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Inscription au cours modifié avec succès",
            });

            resolve(response.data.application);
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(11));
          });
      });
    },
    deleteApplication({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("applications/" + id)
          .then(() => {
            let user = state.user;
            let index = user.student.applications.findIndex(
              (application) => application._id == id
            );
            user.student.applications.splice(index, 1);
            commit("USER", user);

            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Inscription au cours supprimé avec succès",
            });

            resolve();
          })
          .catch((err) => {
            console.log(err.response.data);
            reject(err.response.data);
            Vue.swal.fire(errSwal(12));
          });
      });
    },
  },
  getters: {
    loginState(state) {
      return state.loginState;
    },
    getUser(state) {
      return state.user;
    },
  },
};
