<template>
  <div v-if="!isFetching" class="background">
    <v-container>
      <v-row>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
        <v-col class="mb-5">
          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <v-sheet elevation="2" class="px-4 py-4 mb-2 rounded-lg">
                <v-row>
                  <v-col cols="12">
                    <h2 class="text-h5 text-center">Modification du cours</h2>
                  </v-col>
                </v-row>
                <v-row class="mt-n2">
                  <v-col cols="12">
                    <div class="text-left">
                      <span class="text-h6 font-weight-bold">Statut:</span>
                      <span> {{ course.status }}</span>
                    </div>
                    <div class="text-left">
                      <span class="text-h6 font-weight-bold">Tuteur:</span>
                      <span>
                        {{ course.teacher.firstname }}
                        {{ course.teacher.lastname }}</span
                      >
                    </div>
                    <div class="text-left">
                      <span class="text-h6 font-weight-bold">École:</span>
                      <span> {{ course.teacher.school }}</span>
                    </div>
                    <div class="text-left">
                      <span class="text-h6 font-weight-bold">Année:</span>
                      <span> {{ course.teacher.year }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row class="mt-4">
                  <v-col cols="12">
                    <div class="text-left">
                      <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        disabled
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formatedDate"
                            label="Date du cours"
                            prepend-icon="mdi-calendar"
                            readonly
                            disabled
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          disabled
                          @input="date_menu = false"
                          locale="fr-fr"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="text-left">
                      <v-menu
                        ref="start_menu"
                        v-model="start_time_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="start_hour"
                        disabled
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="start_hour"
                            label="Heure de début"
                            prepend-icon="mdi-clock-time-four-outline"
                            disabled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="start_time_menu"
                          v-model="start_hour"
                          disabled
                          full-width
                          format="24hr"
                          :allowed-minutes="allowedStep"
                          @change="updateEndHour(start_hour)"
                          @click:minute="$refs.start_menu.save(start_hour)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <div class="text-left">
                      <v-menu
                        ref="end_menu"
                        v-model="end_time_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="end_hour"
                        disabled
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="end_hour"
                            label="Heure de fin"
                            prepend-icon="mdi-clock-time-four-outline"
                            disabled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="end_time_menu"
                          v-model="end_hour"
                          disabled
                          full-width
                          format="24hr"
                          :allowed-minutes="allowedStep"
                          @click:minute="$refs.end_menu.save(end_hour)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <div class="text-left">
                      <v-select
                        :items="studentsNumbers"
                        v-model="course_max_student"
                        disabled
                        label="Nombre de tutoré max"
                        prepend-icon="mdi-school"
                        required
                      ></v-select>
                    </div>
                    <div v-if="!confirmed" class="text-left d-flex mt-n2">
                      <v-icon disabled>mdi-cake</v-icon>
                      <div>
                        <v-label>Années des matières</v-label>
                        <div class="d-flex mt-n4">
                          <v-checkbox
                            v-for="level in possible_years"
                            v-model="course.years"
                            disabled
                            :key="level"
                            :label="level"
                            :value="level"
                            class="mx-2"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-left d-flex mt-n2">
                      <v-icon disabled>mdi-cake</v-icon>
                      <div>
                        <v-label>Années des matières</v-label>
                        <div class="d-flex mt-n4">
                          <v-checkbox
                            v-for="level in possible_years"
                            v-model="courseYear"
                            disabled
                            :key="level"
                            :label="level"
                            :value="level"
                            class="mx-2"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="!confirmed" class="text-left mt-2">
                      <v-autocomplete
                        :items="course.materials"
                        item-text="name"
                        item-value="_id"
                        prepend-icon="mdi-book"
                        multiple
                        large
                        dense
                        disabled
                        return-object
                        label="Matière(s) où je vais aider"
                        v-model="course.materials"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            class="mx-1 my-1"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div v-else class="text-left mt-2">
                      <v-autocomplete
                        :items="courseMaterial"
                        item-text="name"
                        item-value="_id"
                        prepend-icon="mdi-book"
                        multiple
                        large
                        dense
                        disabled
                        return-object
                        label="Matière(s) où je vais aider"
                        v-model="courseMaterial"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            class="mx-1 my-1"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div v-if="!confirmed" class="text-left d-flex mt-n2">
                      <v-icon disabled>mdi-map-marker</v-icon>
                      <div>
                        <v-label v-if="!confirmed"
                          >Localisation possible</v-label
                        >
                        <div class="d-flex mt-n4">
                          <v-checkbox
                            v-for="location in possible_location"
                            v-model="course.format_preference"
                            disabled
                            :key="location"
                            :label="location"
                            :value="location"
                            class="mx-2"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="text-left d-flex mt-n2">
                        <v-icon disabled>mdi-map-marker</v-icon>
                        <div>
                          <v-label
                            >Localisation définitive
                            <span
                              style="color: red"
                              v-if="
                                (course.format == 'Présentiel' ||
                                  course.format == 'Hybride') &&
                                course.room == ''
                              "
                            >
                              - BESOIN D'UNE SALLE
                            </span>
                            <span
                              v-else-if="
                                (course.format == 'Présentiel' ||
                                  course.format == 'Hybride') &&
                                course.room != ''
                              "
                            >
                              - {{ course.room }}
                            </span></v-label
                          >
                          <div class="d-flex mt-n4">
                            <v-checkbox
                              v-for="location in possible_location_confirmed"
                              v-model="course.format"
                              disabled
                              :key="location"
                              :label="location"
                              :value="location"
                              class="mx-2"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          currentUser.is_admin && course.status != 'Terminé'
                        "
                        class="mb-1"
                      >
                        <v-btn class="primary" @click="dialog_room = true"
                          ><v-icon class="mr-1 ml-n1">mdi-pencil</v-icon>
                          Modifier localisation</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mb-2">
                  <v-btn
                    large
                    elevation="2"
                    color="error"
                    outlined
                    class="mb-1"
                    @click="$router.go(-1)"
                  >
                    <v-icon>mdi-arrow-left</v-icon> Retour
                  </v-btn>
                  <v-btn
                    @click="delete_course(course._id)"
                    large
                    elevation="2"
                    color="error"
                    class="ml-6"
                  >
                    <v-icon>mdi-delete</v-icon> Supprimer le cours
                  </v-btn>
                </v-row>
              </v-sheet>
            </v-col>
            <v-dialog v-model="dialog_room" width="600">
              <DialogRoom
                :course="course"
                @close="dialog_room = false"
                :adminMod="currentUser.is_admin"
              />
            </v-dialog>
            <v-col cols="12" md="6">
              <div v-if="!confirmed">
                <v-sheet
                  height="750"
                  elevation="2"
                  class="overflow-y-auto pa-4 mb-8 rounded-lg"
                  v-scroll.self="onScroll"
                >
                  <span class="text-h5 black--text mx-4"
                    >Tutorés inscrit au cours
                    <v-btn class="mb-1 mr-2" fab small color="primary">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="currentUser.is_admin"
                      class="copy"
                      fab
                      small
                      elevation="0"
                      @click="copyToClipboardApplicant()"
                    >
                      <v-icon> mdi-content-copy </v-icon>
                    </v-btn>
                  </span>
                  <div v-if="course.applications.length != 0">
                    <div class="mx-3 mt-3">
                      <CardApplication
                        class="mb-3"
                        v-for="application in course.applications"
                        :key="application._id"
                        :application="application"
                        :adminMod="currentUser.is_admin"
                        :modifyCourse="true"
                        :course_status="course.status"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <v-alert type="info" class="mt-2"
                      >Il n'y a pas de tutoré inscrit au cours</v-alert
                    >
                  </div>
                </v-sheet>
              </div>

              <div v-if="confirmed">
                <v-sheet
                  height="355"
                  elevation="2"
                  class="overflow-y-auto pa-4 mb-8 rounded-lg"
                  v-scroll.self="onScroll"
                >
                  <span class="text-h5 black--text mx-4"
                    >Tutorés sélectionnés
                    <v-btn class="mb-1 mr-1" fab small color="primary">
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="currentUser.is_admin"
                      class="copy"
                      fab
                      small
                      elevation="0"
                      @click="copyToClipboardSelected()"
                    >
                      <v-icon> mdi-content-copy </v-icon>
                    </v-btn>
                  </span>
                  <div
                    v-if="course.selected.length + course.missing.length != 0"
                  >
                    <div class="mx-3 mt-3">
                      <CardApplication
                        class="mb-3"
                        v-for="application in mergeSelectedMissing"
                        :key="application._id"
                        :application="application"
                        :adminMod="currentUser.is_admin"
                        :modifyCourse="true"
                        :course_status="course.status"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <v-alert type="info" class="mt-2"
                      >Il n'y a pas de tutoré sélectionné au cours</v-alert
                    >
                  </div>
                </v-sheet>
                <v-sheet
                  height="355"
                  elevation="2"
                  class="overflow-y-auto pa-4 mb-8 rounded-lg"
                  v-scroll.self="onScroll"
                >
                  <span class="text-h5 black--text mx-4"
                    >Tutorés refusés au cours
                    <v-btn
                      v-if="currentUser.is_admin"
                      class="copy"
                      fab
                      small
                      elevation="0"
                      @click="copyToClipboardRefused()"
                    >
                      <v-icon> mdi-content-copy </v-icon>
                    </v-btn>
                  </span>
                  <div v-if="course.refused.length != 0">
                    <div class="mx-3 mt-3">
                      <CardApplication
                        class="mb-3"
                        v-for="application in course.refused"
                        :key="application._id"
                        :application="application"
                        :adminMod="currentUser.is_admin"
                        :modifyCourse="true"
                        :course_status="course.status"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <v-alert type="info" class="mt-2"
                      >Il n'y a pas de tutoré refusé au cours</v-alert
                    >
                  </div>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="copy_clipboard" :timeout="2000" color="success">
      <v-icon>mdi-check</v-icon> Copié dans le presse papier
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="copy_clipboard = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import CardApplication from "../components/CardApplication.vue";
import DialogRoom from "../components/DialogRoom.vue";

export default {
  name: "NewModifyCourse",

  components: {
    CardApplication,
    DialogRoom,
  },
  data: () => ({
    date_menu: false,
    start_time_menu: false,
    end_time_menu: false,
    copy_clipboard: false,

    dialog_room: false,

    scrollInvoked: 0,

    studentsNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],

    possible_years: ["A1", "A2", "A3", "A4", "A5"],

    possible_location: ["En ligne", "Présentiel"],
    possible_location_confirmed: ["En ligne", "Présentiel", "Hybride"],

    isFetching: true,
    confirmed: false,
  }),
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
      course: "courses/getCourse",
    }),
    user: function () {
      return this.currentUser;
    },
    start_hour: function () {
      return dayjs(this.course.start_date).format("HH:mm");
    },
    end_hour: function () {
      return dayjs(this.course.end_date).format("HH:mm");
    },
    course_date: function () {
      return dayjs(this.course.start_date).format("YYYY-MM-DD");
    },
    formatedDate: function () {
      return dayjs(this.course_date).format("DD/MM/YYYY");
    },
    courseYear: function () {
      if (
        this.course.status == "Confirmé" ||
        this.course.status == "En cours" ||
        this.course.status == "Terminé"
      ) {
        return [this.course.material.year];
      }
      return [];
    },
    courseMaterial: function () {
      if (
        this.course.status == "Confirmé" ||
        this.course.status == "En cours" ||
        this.course.status == "Terminé"
      ) {
        return [this.course.material];
      }
      return [];
    },
    course_max_student: function () {
      return this.course.number_of_student_max || 3;
    },
    mergeSelectedMissing: function () {
      return this.course.selected.concat(this.course.missing);
    },
  },
  methods: {
    returnSchool() {
      return this.user.school;
    },
    onScroll() {
      this.scrollInvoked++;
    },
    delete_course(id) {
      this.$store
        .dispatch("user/deleteCourse", { _id: id })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    copyToClipboardApplicant() {
      let str = this.course.applications
        .map((application) => application.applicant.email)
        .join(";");
      navigator.clipboard.writeText(str);
      this.copy_clipboard = true;
    },
    copyToClipboardSelected() {
      let str = this.mergeSelectedMissing
        .map((application) => application.applicant.email)
        .join(";");
      navigator.clipboard.writeText(str);
      this.copy_clipboard = true;
    },
    copyToClipboardRefused() {
      let str = this.course.refused
        .map((application) => application.applicant.email)
        .join(";");
      navigator.clipboard.writeText(str);
      this.copy_clipboard = true;
    },
  },
  beforeMount() {
    this.$store
      .dispatch("user/getCourseById", this.$route.params.id)
      .then((course) => {
        this.$store.dispatch("courses/course", course);
        this.isFetching = false;
        if (
          this.course.status == "Confirmé" ||
          this.course.status == "En cours" ||
          this.course.status == "Terminé"
        ) {
          this.confirmed = true;
          console.log(course);
        }
      })
      .catch(() => {
        this.$router.go(-1);
      });
  },
};
</script>

<style scoped>
.background {
  background-color: #d6d6d6;
  background-image: url(../assets/bg_white_wave.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: auto;
}

.copy:before {
  background: none;
}
</style>
