<template>
  <div class="d-flex justify-center mt-10 mb-16">
    <v-card width="720" height="700">
      <h2
        weight="200"
        class="mt-6 d-flex font-weight-regular justify-center align-center"
      >
        Confirmer les informations
      </h2>
      <v-divider light color="#CF1052" class="mt-4 mb-6 mx-4"></v-divider>
      <FirstLogForm
        :inputUser="user"
        :firstLogStudent="false"
        :adminMod="false"
      />
      <v-form class="justify-center mb-10">
        <div class="text-left d-flex mt-n2 mx-10">
          <v-icon>mdi-cake</v-icon>
          <div>
            <v-label>Année des matières</v-label>
            <div class="d-flex mt-n4">
              <v-checkbox
                v-for="level in levels"
                v-model="user.teacher.years"
                :key="level"
                :label="level"
                :value="level"
                class="mx-2"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="d-flex mt-5 mx-10">
          <v-autocomplete
            :items="filteredMaterials"
            item-text="name"
            item-value="_id"
            prepend-icon="mdi-book"
            multiple
            large
            dense
            return-object
            label="Matières où je peux aider"
            v-model="user.teacher.materials"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                class="mx-1 my-1"
                @click="data.select"
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-form>
      <ValidateErrorButtons
        @successClicked="validate()"
        @errorClicked="problem()"
      >
        <template v-slot:error>SIGNALER UNE ERREUR</template>
        <template v-slot:success>VALIDER</template>
      </ValidateErrorButtons>
    </v-card>
  </div>
</template>

<script>
import FirstLogForm from "../components/FirstLogForm.vue";
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import { mapGetters } from "vuex";

export default {
  name: "TeacherWelcome",
  data: () => ({
    //for teacher years
    levels: ["A1", "A2", "A3", "A4", "A5"],
  }),
  components: {
    FirstLogForm,
    ValidateErrorButtons,
  },
  methods: {
    problem() {
      this.$swal.fire({
        icon: "error",
        title: "Un problème dans tes informations ?",
        text: "Contacte LéoLearning et décris ton problème (un screen du problème est apprecié), en attendant clic sur valider pour acceder à la plateforme ! 🎉",
      });
    },
    validate: async function () {
      let payload = {
        _id: this.user._id,
        years: this.user.teacher.years,
        materials: this.user.teacher.materials.map((material) => {
          return material._id;
        }),
      };
      this.$store.dispatch("user/patchTeacher", payload).finally(() => {
        this.$store.dispatch("user/firstLoginTeacher").finally(() => {
          this.user.teacher.is_first_login = true;
          this.$store.dispatch("user/user", this.user);
          this.$router.push("/profile");
        });
      });
    },
    remove(item) {
      const index = this.user.teacher.materials
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.user.teacher.materials.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      materials: "materials/getMaterials",
    }),
    filteredMaterials() {
      return this.materials.filter((material) => {
        return this.user.teacher.years.includes(material.year);
      });
    },
  },
  beforeMount() {
    //Refresh materials
    this.$store.dispatch("materials/materials", {
      limit: 10000,
      page: 1,
      is_displayed: true,
      school: this.user.school,
    });
  },
};
</script>
<style scoped></style>
