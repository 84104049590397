import axios from "axios";

let options = {
  withCredentials: true,
  baseURL: "http://localhost:3000/",
};

if (process.env.NODE_ENV == "production") {
  options = {
    withCredentials: true,
    baseURL: "https://api.leolearning.fr/",
  };
}

const $axios = axios.create(options);

export default $axios;
