import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ESILV: "#cf1052",
        EMLV: "#0095bb",
        IIM: "#ef7d00",
      },
    },
  },
});
