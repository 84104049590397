<template>
  <v-progress-circular
    :size="80"
    :width="8"
    indeterminate
    color="primary"
    class="content"
  ></v-progress-circular>
</template>

<script>
export default {
  name: "Verify",

  beforeCreate: function () {
    console.log(this.$route.fullPath);
    try {
      var regex = /#access_token=(.*)&token_type/;
      var token = this.$route.fullPath.match(regex)[1];
      this.$store.dispatch("user/connectLeoid", token);
    } catch {
      this.$router.push("/");
    }
  },
  components: {},
};
</script>

<style scoped>
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
