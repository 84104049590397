import Vue from "vue";
import Vuex from "vuex";
import materials from "./materials";
import courses from "./courses";
//import exams from "./exams";
import user from "./user";
import admin from "./admin";
import local from "./local";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    materials: materials,
    courses: courses,
    //exams: exams,
    admin: admin,
    local: local,
  },
});
