<template>
  <v-app class="bg">
    <NavBar />
    <router-view :key="$route.fullPath" />
    <FooterApp />
  </v-app>
</template>

<script>
import FooterApp from "./components/FooterApp.vue";
import NavBar from "./components/NavBar";
export default {
  name: "App",
  components: { NavBar, FooterApp },
  data: () => ({
    //
  }),
};
</script>
<style>
.swal2-overlay {
  font-family: "Roboto", Helvetica, sans-serif !important;
}
.swal2-title {
  font-family: "Roboto", Helvetica, sans-serif !important;
}
.swal2-popup {
  font-family: "Roboto", Helvetica, sans-serif !important;
}

.bg {
  background-color: #d6d6d6;
}
body {
  overflow: hidden;
}
</style>
