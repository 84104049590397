<template class="nav_ordi">
  <div>
    <div class="desktop_menu" v-bind:class="{ absolute: isHome }">
      <div class="main_logo">
        <div class="nav_left_part">
          <router-link
            to="/"
            style="text-decoration: none; color: #353535"
            class="d-flex flex-column justify-space-around"
          >
            <p v-if="!loginState">
              <v-icon class="ml-1" size="25">mdi-home</v-icon>Accueil
            </p>
            <p v-else>
              <v-icon class="ml-1" size="25">mdi-account</v-icon> Profil
            </p>
          </router-link>
          <router-link
            v-if="loginState"
            to="/edt"
            style="text-decoration: none; color: #353535"
            class="d-flex flex-column justify-space-around"
          >
            <p><v-icon class="ml-1" size="25">mdi-calendar</v-icon> E.D.T</p>
          </router-link>
        </div>
        <img
          src="../assets/LL_logo.png"
          alt="main_logo"
          height="40"
          width="auto"
          class="logo_LL"
        />
        <div class="nav_right_part">
          <router-link
            to="/admin"
            v-if="loginState && currentUser.is_admin"
            class="d-flex flex-column justify-space-around"
            style="text-decoration: none; color: #353535"
          >
            <p>
              <v-icon class="ml-1" size="25">mdi-shield-crown-outline</v-icon>
              Admin
            </p>
          </router-link>
          <router-link
            to="/about"
            class="d-flex flex-column justify-space-around"
            style="text-decoration: none; color: #353535"
          >
            <p>
              <v-icon class="ml-1" size="25">mdi-information</v-icon>
              Informations
            </p>
          </router-link>
          <v-btn
            @click="disconnect()"
            class="d-flex justify-space-around"
            color="red"
            v-if="loginState"
          >
            <span>Déconnexion</span>
            <v-icon class="ml-1" size="20">mdi-logout</v-icon>
          </v-btn>
        </div>
        <v-btn
          href="https://api.leolearning.fr/qrcode/links_web"
          target="_blank"
          text
          absolute
          right
          style="display: none"
          class="d-lg-block"
        >
          <span class="mt-2">
            <v-icon class="ml-1" size="25">mdi-open-in-new</v-icon>
          </span></v-btn
        >
      </div>
    </div>
    <div class="burger_menu">
      <v-app-bar>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item
              @click="$router.push('/')"
              style="text-decoration: none; color: #353535"
            >
              <p class="mt-8 mb-8" v-if="!loginState">
                <v-icon class="ml-1" size="25">mdi-home</v-icon>Accueil
              </p>
              <p class="mt-8 mb-8" v-else>
                <v-icon class="ml-1" size="25">mdi-account</v-icon> Profil
              </p>
            </v-list-item>
            <v-list-item
              v-if="loginState"
              @click="$router.push('/edt')"
              style="text-decoration: none; color: #353535"
            >
              <p class="mt-8 mb-8">
                <v-icon class="ml-1" size="25">mdi-calendar</v-icon> E.D.T
              </p>
            </v-list-item>
            <v-list-item
              @click="$router.push('/admin')"
              v-if="loginState && currentUser.is_admin"
              style="text-decoration: none; color: #353535"
            >
              <p class="mt-8 mb-8">
                <v-icon class="ml-1" size="25">mdi-shield-crown-outline</v-icon>
                Admin
              </p>
            </v-list-item>
            <v-list-item
              @click="$router.push('/about')"
              style="text-decoration: none; color: #353535"
            >
              <p class="mt-8 mb-8">
                <v-icon class="ml-1 mr-2" size="25">mdi-information</v-icon
                >Informations
              </p>
            </v-list-item>
            <v-list-item
              @click="disconnect()"
              v-if="loginState"
              style="text-decoration: none; color: #353535"
            >
              <p class="mt-8 mb-8">
                <v-icon class="ml-1 mb-1 mr-2" size="20">mdi-logout</v-icon
                >Déconnexion
              </p>
            </v-list-item>
            <v-list-item
              href="https://api.leolearning.fr/qrcode/links_web"
              target="_blank"
            >
              <span class="">Nos réseaux:</span>
              <v-icon class="ml-1" size="25">mdi-open-in-new</v-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    drawer: false,
    group: null,
    absolute: "absolute",
    isHome: true,
  }),
  methods: {
    disconnect() {
      this.$store.dispatch("user/disconnect");
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    ...mapGetters({
      loginState: "user/loginState",
      currentUser: "user/getUser",
    }),
  },
  mounted: function () {
    if (this.$route.fullPath == "/") {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  },
};
</script>

<style>
.v-application p {
  margin: 0 !important;
}
.absolute {
  position: absolute;
  width: 100%;
}

.desktop_menu {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 10;
}

.main_logo {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f5f5f5;
  padding: 12px 0;
  align-items: center;
}
.nav_left_part {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  background: #f5f5f5;
}
.nav_right_part {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  background: #f5f5f5;
}
router-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.burger_menu {
  display: none;
}
@media (max-width: 600px) {
  .logo_LL {
    display: none;
  }
  .burger_menu {
    display: block;
  }
  .desktop_menu {
    display: none;
  }
  .testttttt {
    height: 100%;
  }
}
</style>
