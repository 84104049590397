<template>
  <div>
    <h3>Statistiques :</h3>
    <p>Nombre de cours demandés: {{ user.student.applications.length }}</p>
    <p>Nombre de cours suivis et terminés: {{ followed }}</p>
    <p>Nombre de cours refusés par l'algorithme: {{ refused }}</p>
    <p>Nombre de cours annulés par toi ou le tuteur: {{ cancelled }}</p>
    <p>Nombre d'absences: {{ missed }}</p>
    <div v-if="user.is_teacher">
      <v-divider
        class="lighten-1 mt-5 mb-3 ml-0 mr-0"
        v-bind:class="user.school"
      ></v-divider>
      <h3>Statistiques tuteur :</h3>
      <p>Nombre de cours proposés: {{ user.teacher.courses.length }}</p>
      <p>Nombre de cours terminés: {{ done }}</p>
      <p>Nombre d'étudiants aidés: {{ helped }}</p>
      <p>Nombre moyen d'étudiant par cours: {{ meanStudent }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsProfile",
  props: {
    user: Object,
  },

  computed: {
    followed() {
      //Nombre de demande accepté
      return this.user.student.applications.filter((application) => {
        return (
          application.status == "Acceptée" &&
          application.course.status == "Terminé"
        );
      }).length;
    },
    refused() {
      //Nombre de demande accepté
      return this.user.student.applications.filter((application) => {
        return application.status == "Refusé";
      }).length;
    },
    cancelled() {
      //Nombre de demande accepté
      return this.user.student.applications.filter((application) => {
        return (
          application.status == "Annulée" ||
          application.status == "Cours annulé"
        );
      }).length;
    },
    missed() {
      //Nombre de demande accepté
      return this.user.student.applications.filter((application) => {
        return application.status == "Absent";
      }).length;
    },
    done() {
      if (this.user.is_teacher) {
        let cpt = 0;
        this.user.teacher.courses.forEach((course) => {
          if (course.status == "Terminé") {
            cpt += 1;
          }
        });
        return cpt;
      } else {
        return 0;
      }
    },
    helped() {
      if (this.user.is_teacher) {
        let cpt = 0;
        this.user.teacher.courses.forEach((course) => {
          if (course.status == "Terminé") {
            cpt += course.selected.length;
          }
        });
        return cpt;
      } else {
        return 0;
      }
    },
    meanStudent() {
      if (this.user.is_teacher) {
        if (this.done == 0) {
          return 0;
        } else {
          return this.helped / this.done;
        }
      } else {
        return 0;
      }
    },
  },
};
</script>
