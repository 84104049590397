<template>
  <v-card class="py-4">
    <v-icon @click.stop="$emit('close')" large class="closeIcon mx-2 mt-2"
      >mdi-close</v-icon
    >
    <h2 class="text-center font-weight-light">Exporter les données</h2>

    <div class="my-8" style="width: 66%; margin-left: 17%">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            label="Filtrez par la date de début et de fin"
            hint="DD/MM/YYYY format"
            persistent-hint
            clearable
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :first-day-of-week="1"
          range
          locale="fr-fr"
          class="custom-date-picker d-flex align-center justify-center"
        ></v-date-picker>
      </v-menu>
    </div>

    <div class="d-flex align-center justify-center">
      <v-btn color="green" :loading="!isLoading" @click="exportData()">
        <span>Exporter</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import * as XLSX from "xlsx-js-style";

export default {
  name: "DialogEditUser",
  props: {},
  data: () => ({
    date: [
      dayjs(Date.now())
        .subtract(parseInt(dayjs(Date.now()).get("date")) - 1, "day")
        .format("YYYY-MM-DD"),
      dayjs(Date.now()).format("YYYY-MM-DD"),
    ],
    menu: false,
    isLoading: false,
  }),
  methods: {
    exportData() {
      if (!this.isLoading) return;
      if (dayjs(this.date[0]) > dayjs(this.date[1])) {
        let tmp = this.date[0];
        this.date[0] = this.date[1];
        this.date[1] = tmp;
      }

      const colorsCorrespondance = {
        ESILV: "D11758",
        EMLV: "0A99BE",
        IIM: "EF8108",
      };
      // Création du 'book' (=classeur)
      const wb = XLSX.utils.book_new();

      // Création de la feuille des stats des cours
      var listeCoursesFin = [
        [
          {
            v: "Date",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Horaires",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Catégories",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Action",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nom du tuteur",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Matière",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Année",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Status",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nom des tutorés",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
        ],
      ];
      var listeCourses = [];
      var listeTuteursFirst = {};
      if (this.allCourses.length == 0) {
        listeCoursesFin = [{ "Aucun cours": "" }];
      } else {
        listeCourses = this.allCourses.filter((course) => {
          if (course) {
            return (
              dayjs(course.start_date).format("YYYY-MM-DD") >= this.date[0] &&
              dayjs(course.end_date).format("YYYY-MM-DD") <= this.date[1]
            );
          }
        });
        if (listeCourses.length == 0) {
          listeCourses = [{ "Aucun cours": "" }];
        } else {
          listeCourses.map((course) => {
            let teacherResult;
            if (course.teacher) {
              teacherResult =
                course.teacher.lastname + " " + course.teacher.firstname;

              if (listeTuteursFirst[course.teacher.email]) {
                listeTuteursFirst[course.teacher.email].nbrCoursProposes += 1;
                if (course.status == "Terminé") {
                  listeTuteursFirst[course.teacher.email].coursesDone += 1;
                  listeTuteursFirst[course.teacher.email].studentHelped +=
                    course.selected.length;
                }
              } else {
                listeTuteursFirst[course.teacher.email] = {
                  nbrCoursProposes: 0,
                  coursesDone: 0,
                  studentHelped: 0,
                };
              }
            } else {
              teacherResult = "Aucun tuteur";
            }
            console.log(course);
            console.log(course.applications);
            let studentResult = "";
            if (
              course.status == "En attente de confirmation" ||
              course.status == "Annulé"
            ) {
              if (course.applications.length > 0) {
                course.applications.forEach((student) => {
                  studentResult +=
                    student.applicant.lastname +
                    " " +
                    student.applicant.firstname +
                    ", ";
                });
                studentResult = studentResult.substring(
                  0,
                  studentResult.length - 2
                );
              } else {
                studentResult = "Aucun tutoré";
              }
            } else {
              if (course.selected.length > 0) {
                course.selected.forEach((student) => {
                  studentResult +=
                    student.applicant.lastname +
                    " " +
                    student.applicant.firstname +
                    ", ";
                });
                studentResult = studentResult.substring(
                  0,
                  studentResult.length - 2
                );
              } else {
                studentResult = "Aucun tutoré";
              }
            }
            let fillColor = "#FFFFFF";
            let materialsResult = "";
            if (
              course.status == "En attente de confirmation" ||
              course.status == "Annulé"
            ) {
              if (course.materials.length > 0) {
                fillColor = colorsCorrespondance[course.materials[0].school];
                course.materials.forEach((material) => {
                  materialsResult += material.name + ", ";
                });
                materialsResult = materialsResult.substring(
                  0,
                  materialsResult.length - 2
                );
              } else {
                materialsResult = "Aucune matière";
              }
            } else {
              if (!course.material.length) {
                fillColor = colorsCorrespondance[course.material.school];
                materialsResult = course.material.name;
              } else if (course.material.length > 0) {
                fillColor = colorsCorrespondance[course.material[0].school];
                course.material.forEach((material) => {
                  materialsResult += material.name + ", ";
                });
                materialsResult = materialsResult.substring(
                  0,
                  materialsResult.length - 2
                );
              } else {
                materialsResult = "Aucune matière";
              }
            }
            let yearsResult = "";
            if (
              course.status == "En attente de confirmation" ||
              course.status == "Annulé"
            ) {
              if (course.years.length > 0) {
                course.years.forEach((year) => {
                  yearsResult += year + ", ";
                });
                yearsResult = yearsResult.substring(0, yearsResult.length - 2);
              } else {
                yearsResult = "Aucune année";
              }
            } else {
              if (course.year != "") {
                yearsResult = course.year;
              } else {
                yearsResult = "Aucune année";
              }
            }
            listeCoursesFin.push([
              {
                v: dayjs(course.start_date).format("DD/MM/YYYY"),
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v:
                  dayjs(course.start_date).format("HH:mm") +
                  "-->" +
                  dayjs(course.end_date).format("HH:mm"),
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: course.type == "course" ? "Tutorat" : course.type,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: "Semaine classique",
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: teacherResult,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: materialsResult,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: yearsResult,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: course.status,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: studentResult,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
            ]);
          });
        }
      }
      const wsCourses = XLSX.utils.aoa_to_sheet(listeCoursesFin);
      XLSX.utils.book_append_sheet(wb, wsCourses, "Cours");

      //Création de la feuille des stats des tuteurs
      var listeTuteursFin = [
        [
          {
            v: "Nom",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Prénom",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "École",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nombre de cours proposés",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nombre de cours terminées",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nombre d'étudiants aidés",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Nombre moyen d'étudiants par cours",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
        ],
      ];
      var listeTuteurs = [];
      if (this.allUsers.length == 0) {
        listeTuteursFin = [{ "Aucun tuteur": "" }];
      } else {
        listeTuteurs = this.allUsers.filter((user) => {
          return user.is_teacher;
        });
        if (listeTuteurs.length == 0) {
          listeTuteursFin = [{ "Aucun tuteur": "" }];
        } else {
          listeTuteurs.map((tuteur) => {
            let nbrCoursProposes = 0;
            let coursesDone = 0;
            let studentHelped = 0;
            if (listeTuteursFirst[tuteur.email]) {
              nbrCoursProposes =
                listeTuteursFirst[tuteur.email].nbrCoursProposes;
              coursesDone = listeTuteursFirst[tuteur.email].coursesDone;
              studentHelped = listeTuteursFirst[tuteur.email].studentHelped;
            }
            let averageStudent = 0;
            if (coursesDone != 0) averageStudent = studentHelped / coursesDone;
            let fillColor = colorsCorrespondance[tuteur.school];
            listeTuteursFin.push([
              {
                v: tuteur.lastname,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: tuteur.firstname,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: tuteur.school,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: nbrCoursProposes,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: coursesDone,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: studentHelped,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: averageStudent,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
            ]);
          });
        }
      }
      const wsTuteurs = XLSX.utils.aoa_to_sheet(listeTuteursFin);
      XLSX.utils.book_append_sheet(wb, wsTuteurs, "Tuteurs");

      // Création de la feuille des stats des tutorés
      var listeTutoresFin = [
        [
          {
            v: "Nom",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Prénom",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "École",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
          {
            v: "Date d'inscription",
            t: "s",
            s: {
              font: { bold: true, sz: 16 },
              fill: { fgColor: { rgb: "808080" } },
              alignment: { vertical: "center" },
            },
          },
        ],
      ];
      var listeTutores = [];
      if (this.allUsers.length == 0) {
        listeTutoresFin = [{ "Aucun tutoré": "" }];
      } else {
        listeTutores = this.allUsers.filter((user) => {
          return (
            user.is_student &&
            dayjs(user.created_at).format("YYYY-MM-DD") >= this.date[0] &&
            dayjs(user.created_at).format("YYYY-MM-DD") <= this.date[1]
          );
        });
        if (listeTutores.length == 0) {
          listeTutoresFin = [{ "Aucun tutoré": "" }];
        } else {
          listeTutores.map((tutore) => {
            let fillColor = colorsCorrespondance[tutore.school];
            listeTutoresFin.push([
              {
                v: tutore.lastname,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: tutore.firstname,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: tutore.school,
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
              {
                v: dayjs(tutore.created_at).format("DD/MM/YYYY"),
                t: "s",
                s: { fill: { fgColor: { rgb: fillColor } } },
              },
            ]);
          });
        }
      }
      const wsTutores = XLSX.utils.aoa_to_sheet(listeTutoresFin);
      XLSX.utils.book_append_sheet(wb, wsTutores, "Tutorés");

      //Création et téléchargement du fichier
      const filePath =
        "Export du " +
        dayjs(this.date[0]).format("DD-MM-YYYY") +
        " au " +
        dayjs(this.date[1]).format("DD-MM-YYYY") +
        ".xlsx";
      XLSX.writeFile(wb, filePath, { bookType: "xlsx", type: "file" });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    ...mapGetters({
      allCourses: "admin/getAllCourses",
      allUsers: "admin/getAllUsers",
      user: "user/getUser",
    }),
    computedDateFormatted: {
      get() {
        if (dayjs(this.date[0]) > dayjs(this.date[1])) {
          return (
            this.formatDate(this.date[1]) +
            " 👉 " +
            this.formatDate(this.date[0])
          );
        } else {
          return (
            this.formatDate(this.date[0]) +
            " 👉 " +
            this.formatDate(this.date[1])
          );
        }
      },
      set() {
        this.date = [];
      },
    },
  },
  beforeMount: async function () {
    await Promise.all([
      this.$store.dispatch("admin/importAllCourses"),
      this.$store.dispatch("admin/importAllUsers"),
    ]).finally(() => {
      this.isLoading = true;
    });
  },
};
</script>
<style scoped>
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.custom-date-picker .v-date-picker-table {
  max-width: 250px; /* Ajustez la largeur selon vos besoins */
}
</style>
