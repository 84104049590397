<template>
  <div class="d-flex justify-center mt-16 mb-16">
    <v-card width="720" height="426">
      <h2
        weight="200"
        class="mt-6 d-flex font-weight-regular justify-center align-center"
      >
        Confirmer les informations
      </h2>
      <v-divider light color="#EF7D00" class="mt-4 mb-6 mx-4"></v-divider>
      <FirstLogForm
        :inputUser="this.user"
        :firstLogStudent="true"
        :adminMod="false"
      />
      <ValidateErrorButtons
        @successClicked="validate()"
        @errorClicked="problem()"
      >
        <template v-slot:error>SIGNALER UNE ERREUR</template>
        <template v-slot:success>VALIDER</template>
      </ValidateErrorButtons>
    </v-card>
  </div>
</template>

<script>
import FirstLogForm from "../components/FirstLogForm.vue";
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import { mapGetters } from "vuex";

export default {
  name: "StudentWelcome",
  components: {
    FirstLogForm,
    ValidateErrorButtons,
  },
  methods: {
    problem: function () {
      //TODO: Add popup
      this.$swal.fire({
        icon: "error",
        title: "Un problème dans tes informations ?",
        text: "Contacte LéoLearning et décris ton problème (un screen du problème est apprecié), en attendant clic sur valider pour acceder à la plateforme ! 🎉",
      });
    },
    validate: function () {
      this.$store
        .dispatch("user/firstLogin", {
          year: this.user.year,
          school: this.user.school,
        })
        .finally(() => {
          this.$router.push("/profile");
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
};
</script>

<style scoped></style>
