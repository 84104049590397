<template>
  <v-card>
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >

    <v-card-title class="text-h5"> Création d'une matière </v-card-title>

    <v-form ref="form_create">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="d-flex">
              <v-select
                :items="schools"
                v-model="school"
                label="Ecole"
                placeholder="Ecole"
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="years"
              v-model="year"
              label="Année"
              placeholder="Anneé"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mt-n2">
          <v-col cols="12" sm="6">
            <div class="d-flex">
              <v-text-field
                v-model="module"
                label="Module"
                placeholder="Module"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="semesters"
              v-model="semester"
              label="Semestre"
              placeholder="Semestre"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mt-n2">
          <v-col cols="12" sm="6">
            <div class="d-flex">
              <v-text-field v-model="name" label="Nom" placeholder="Nom">
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="text-center">
            <v-switch v-model="is_displayed">
              <template v-slot:label>
                <span v-if="is_displayed">
                  <v-icon>mdi-eye</v-icon> Sera visible
                </span>
                <span v-else>
                  <v-icon>mdi-eye-off</v-icon> Ne sera pas visible
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-divider></v-divider>
    <v-container>
      <v-row class="text-center">
        <v-col cols="6"
          ><v-btn
            v-on:click.stop="$emit('close')"
            outlined
            elevation="2"
            color="error"
          >
            <v-icon>mdi-close</v-icon> Fermer</v-btn
          ></v-col
        >
        <v-col cols="6">
          <v-btn
            color="success"
            elevation="2"
            width=""
            v-if="create"
            @click="create_material()"
          >
            <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
          </v-btn>
          <v-btn
            color="success"
            elevation="2"
            width=""
            v-else
            @click="edit_material()"
          >
            <v-icon class="mr-1">mdi-pencil</v-icon> VALIDER
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "DialogMaterial",
  props: {
    src_material: Object,
    create: Boolean,
    _id: String,
  },
  data: () => ({
    schools: ["ESILV", "EMLV", "IIM"],
    years: ["A1", "A2", "A3", "A4", "A5"],
    semesters: ["S1", "S2"],

    name: "",
    module: "",
    school: "",
    year: "A1",
    semester: "S1",
    is_displayed: true,
  }),
  methods: {
    create_material() {
      this.$store
        .dispatch("materials/createMaterial", this.material)
        .then(() => {
          this.reset_material();
          this.$emit("close");
        })
        .catch(() => {
          this.$emit("close");
        });
    },
    edit_material() {
      this.$store
        .dispatch("materials/patchMaterial", this.material)
        .then(() => {
          this.$emit("close");
        })
        .catch(() => {
          this.$emit("close");
        });
    },
    reset_material() {
      this.name = "";
      this.module = "";
      this.school = "ESILV";
      this.year = "A1";
      this.semester = "S1";
      this.is_displayed = true;
    },
  },
  computed: {
    material() {
      if (!this.create) {
        return {
          _id: this._id,
          name: this.name,
          module: this.module,
          school: this.school,
          year: this.year,
          semester: this.semester,
          is_displayed: this.is_displayed,
        };
      } else {
        return {
          name: this.name,
          module: this.module,
          school: this.school,
          year: this.year,
          semester: this.semester,
          is_displayed: this.is_displayed,
        };
      }
    },
  },
  created() {
    if (this.src_material != {}) {
      this.name = this.src_material.name || "";
      this.module = this.src_material.module || "";
      this.school = this.src_material.school || "ESILV";
      this.year = this.src_material.year || "A1";
      this.semester = this.src_material.semester || "S1";
      this.is_displayed = this.src_material.is_displayed;
    } else {
      this.reset_material();
    }
  },
};
</script>
<style scoped>
.v-sheet {
  position: relative;
  color: #8f8f8f;
}
.v-icon,
.v-input {
  color: #8f8f8f;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}

.discord_link {
  color: inherit;
}
</style>
