<template>
  <v-card v-model="dialog_activated" class="pb-2">
    <v-icon @click="$emit('close')" large class="closeIcon mt-2 ml-2">
      mdi-close
    </v-icon>
    <h3 class="d-flex font-weight-regular text-h4 justify-center">
      Rentrez le code de verification
    </h3>
    <v-text-field
      label="Code"
      type="password"
      v-model="code"
      style="width: 50px; margin-left: 40%"
    ></v-text-field>
    <v-btn style="margin-left: 35%" color="green" @click="validate">
      <span>Valider</span>
    </v-btn>

    <!-- <v-otp-input
      ref="otp"
      
      type="password"
      class="mx-auto pb-2"
      style="width: 100%; height: 100%; max-width: 300px"
      @input="checkOtpCompletion"
    ></v-otp-input> -->
  </v-card>
</template>

<script>
export default {
  name: "DialogCodeVerification",
  props: {
    user: Object,
  },
  data: () => ({
    dialog_activated: false,
    code: "",
  }),
  methods: {
    validate() {
      let payload = {
        _id: this.user._id,
        is_admin: !this.user.is_admin,
        code: this.code,
      };
      this.$store.dispatch("admin/modifyUser", payload, this.code);
      this.$emit("close");
    },
  },
  mounted() {
    this.code = "";
  },
};
</script>
