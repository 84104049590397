<template>
  <div>
    <v-alert
      v-if="
        user.is_teacher &&
        showTeacher &&
        !showStudent &&
        user.teacher.courses.length == 0 &&
        cards.length != 0
      "
      type="info"
      class="mx-4 my-4"
      >Vous n'avez proposé aucun cours</v-alert
    >
    <div v-else-if="cards.length == 0">
      <v-alert v-if="user.is_teacher" type="info" class="mx-4 my-4"
        >Vous n'êtes inscrit a aucun cours et n'avez proposé aucun
        cours</v-alert
      >
      <v-alert v-else type="info" class="mx-4 my-4"
        >Vous n'êtes inscrit a aucun cours</v-alert
      >
    </div>
    <div v-else>
      <div v-for="card in filteredCard" :key="card._id">
        <CardApplication
          class="mx-3 my-3"
          v-if="card.type == 'application'"
          :application="card"
          :adminMod="adminMod"
          :modifyCourse="false"
          :course_status="card.course.status"
        />
        <CardCourse
          class="mx-3 my-3"
          v-else-if="card.type == 'course'"
          :course="card"
          :isAdminMode="adminMod"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardApplication from "./CardApplication.vue";
import CardCourse from "./CardCourse.vue";
import { mapGetters } from "vuex";

export default {
  name: "CardWrapper",
  components: {
    CardApplication,
    CardCourse,
  },
  props: {
    cards: Array,
    filters: Object,
    adminMod: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    filteredCard: function () {
      let filteredCards = this.cards.filter((card) => {
        if (card.type == "application") {
          let course_passed = card.course.status == "Terminé";
          if (!this.showStudent) {
            return false;
          }
          if (this.showUpcoming) {
            if (
              card.status == "En attente" ||
              (card.status == "Acceptée" && !course_passed)
            ) {
              return true;
            }
          }
          if (this.showPassed) {
            if (
              card.status == "Refusée" ||
              card.status == "Annulée" ||
              card.status == "Cours annulé" ||
              card.status == "Absent" ||
              (card.status == "Acceptée" && course_passed)
            ) {
              return true;
            }
          }
          return false;
        } else if (card.type == "course") {
          if (!this.showTeacher) {
            return false;
          }
          if (this.showUpcoming) {
            if (
              card.status == "En attente de confirmation" ||
              card.status == "Confirmé" ||
              card.status == "En cours"
            ) {
              return true;
            }
          }
          if (this.showPassed) {
            if (card.status == "Terminé" || card.status == "Annulé") {
              return true;
            }
          }
          return false;
        }
      });
      return filteredCards;
    },
    showTeacher: function () {
      return (
        this.filters.filter1.includes("teacher") ||
        this.filters.filter1.length == 0
      );
    },
    showStudent: function () {
      return (
        this.filters.filter1.includes("student") ||
        this.filters.filter1.length == 0
      );
    },
    showUpcoming: function () {
      return (
        this.filters.filter2.includes("upcoming") ||
        this.filters.filter2.length == 0
      );
    },
    showPassed: function () {
      return (
        this.filters.filter2.includes("passed") ||
        this.filters.filter2.length == 0
      );
    },
  },
};
</script>
