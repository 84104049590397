<template>
  <div>
    <v-form class="d-flex flex-column px-10 py-4">
      <div class="d-flex">
        <v-text-field
          class="pr-15"
          label="Prénom"
          required
          block
          color="second"
          :readonly="!adminMod"
          outlined
          v-model="user.firstname"
          @change="triggerChange()"
        ></v-text-field>
        <v-text-field
          label="Nom"
          required
          block
          :readonly="adminMod ? true : false"
          outlined
          v-model="user.lastname"
          @change="triggerChange()"
        ></v-text-field>
      </div>
      <div class="d-flex" v-if="!firstLogStudent">
        <v-text-field
          class="pr-15"
          block
          label="Ecole"
          required
          readonly
          outlined
          v-model="user.school"
        ></v-text-field>
        <v-text-field
          block
          label="Année"
          required
          readonly
          outlined
          v-model="user.year"
        ></v-text-field>
      </div>
      <div class="d-flex" v-else>
        <v-select
          class="pr-15"
          block
          label="Ecole"
          required
          outlined
          :items="schools"
          v-model="user.school"
          @change="triggerChange()"
        ></v-select>
        <v-select
          block
          label="Année"
          required
          outlined
          :items="years"
          v-model="user.year"
          @change="triggerChange()"
        ></v-select>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "FirstLogForm",
  props: {
    inputUser: Object,
    firstLogStudent: Boolean,
    adminMod: Boolean,
  },
  methods: {
    triggerChange() {
      if (this.adminMod) {
        this.$store.dispatch("admin/setSpecificUser", this.user);
      } else {
        this.$store.dispatch("user/user", this.user);
      }
    },
  },
  data() {
    return {
      selected: [],
      user: this.inputUser,

      years: ["A1", "A2", "A3", "A4", "A5"],
      schools: ["ESILV", "EMLV", "IIM"],
    };
  },
};
</script>

<style scoped></style>
