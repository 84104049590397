<template>
  <v-card elevation="1" two-line class="ma-2 rounded item bg-white">
    <v-card-title class="pb-0 text-subtitle-2 grey--text text--darken-2"
      ><v-icon dense class="mr-1">mdi-clock-outline</v-icon
      >{{ formatted_start_hour }} <v-icon small>mdi-arrow-right</v-icon>
      {{ formatted_end_hour }}</v-card-title
    >
    <v-card-text>
      <div>
        {{ course.format_preference.join(", ") }}
      </div>
    </v-card-text>
    <v-expansion-panels class="px-2" elevation="0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Matières disponibles
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-for="material in proper_material" :key="material._id">
            - {{ material.name }} <br
          /></span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions class="justify-center text-center">
      <v-dialog v-model="dialog" width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="primary justify-center" small v-on="on" v-bind="attrs">
            S'inscrire
          </v-btn>
        </template>
        <v-card>
          <v-form ref="form">
            <v-card-title class="text-h5"> Inscription </v-card-title>

            <v-card-text class="mt-2">
              <v-icon dense class="mr-1 mb-1">mdi-clock-outline</v-icon> Cours
              de {{ formatted_start_hour }} à {{ formatted_end_hour }} le
              {{ formatted_date }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="mt-2">
              <p>Matière(s) où tu voudrais avoir cours</p>
              <v-autocomplete
                :items="proper_material"
                class="mt-2"
                item-text="name"
                item-value="_id"
                prepend-icon="mdi-book"
                multiple
                large
                required
                dense
                return-object
                placeholder="Matière(s) où tu voudrais avoir cours"
                @change="modified_course = true"
                :rules="material_rule"
                v-model="course_materials"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    class="mx-1 my-1"
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="mt-2">
              <p>Localisation voulue</p>
              <div class="text-left d-flex mt-n2">
                <div class="d-flex ml-n2">
                  <v-checkbox
                    v-for="location in possible_location"
                    v-model="format_preference"
                    :key="location"
                    :label="location"
                    :value="location"
                    :rules="checkbox_rule"
                    :prepend-icon="icon_dict[location]"
                    class="mx-2"
                  ></v-checkbox>
                </div>
              </div>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <p>Description pour le tuteur</p>
              <v-textarea
                class="mt-n2"
                v-model="description"
                prepend-icon="mdi-comment"
                rows="2"
                :rules="comment_rule"
                placeholder="Description pour le tuteur"
                required
              ></v-textarea>
            </v-card-text>

            <v-divider></v-divider>
            <div class="text-center justify-center">
              <v-btn
                color="success"
                class="my-3"
                elevation="2"
                @click="validate()"
              >
                VALIDER
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>
<script>
import dayjs from "dayjs";

export default {
  props: {
    course: Object,
    user: Object,
    preselected_materials: Array,
    preselected_format: Array,
  },
  data: () => ({
    dialog: false,
    modified_course: false,
    modified_preference: false,

    icon_dict: {
      "En ligne": "mdi-laptop",
      Présentiel: "mdi-domain",
    },

    course_materials: [],
    format_preference: [],
    description: "",
  }),
  methods: {
    remove(item) {
      const index = this.course_materials
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.course_materials.splice(index, 1);
    },

    validate() {
      if (this.$refs.form.validate()) {
        //Call API
        this.$store
          .dispatch("user/createApplication", {
            course: this.course._id,
            materials: this.course_materials.map((mat) => mat._id),
            format_preference: this.format_preference,
            description: this.description,
          })
          .then(() => {
            this.dialog = false;
            this.$router.push("/profile");
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    dialog(newDialog, oldDialog) {
      //TODO: Review opening and closing of a dialog, what to keep when closing ? What/When import from filter ?
      if (
        this.preselected_materials.length > 0 &&
        this.course_materials.length == 0 &&
        oldDialog == false &&
        newDialog == true &&
        this.modified_course == false
      ) {
        let course_materials_id = this.course.materials.map(
          (materials) => materials._id
        );
        for (let material of this.preselected_materials) {
          if (course_materials_id.includes(material._id)) {
            this.course_materials.push(material);
          }
        }
      }
      if (
        this.preselected_format.length > 0 &&
        this.format_preference.length == 0 &&
        oldDialog == false &&
        newDialog == true &&
        this.modified_preference == false
      ) {
        for (let format of this.preselected_format) {
          if (this.course.format_preference.includes(format)) {
            this.format_preference.push(format);
          }
        }
      }
      if (
        oldDialog == true &&
        newDialog == false &&
        (this.modified_course == false ||
          (this.course_materials.length == 0 &&
            this.format_preference.length == 0))
      ) {
        this.course_materials = [];
        this.format_preference = [];
        this.modified_course = false;
      }
      if (oldDialog == false && newDialog == true) {
        if (this.course.format_preference.length == 1) {
          this.format_preference = this.course.format_preference;
        }
      }
      if (oldDialog == true && newDialog == false) {
        this.$refs.form.resetValidation();
      }
    },
  },
  computed: {
    formatted_start_hour: function () {
      return dayjs(this.course.start_date).format("HH:mm").replace(":", "h");
    },
    formatted_end_hour: function () {
      return dayjs(this.course.end_date).format("HH:mm").replace(":", "h");
    },
    formatted_date: function () {
      return dayjs(this.course.start_date).format("DD/MM/YYYY");
    },
    possible_location: function () {
      return this.course.format_preference;
    },
    material_rule: function () {
      return [
        this.course_materials.length > 0 ||
          "Au moins une des matières doit être sélectionné",
      ];
    },
    checkbox_rule: function () {
      return [
        this.format_preference.length > 0 ||
          "Au moins un des champs doit être sélectionné",
      ];
    },
    comment_rule: function () {
      return [
        this.description != "" || "Description ne doit pas être vide",
        this.description.length > 20 || "Expliquez longement votre problème",
      ];
    },
    proper_material: function () {
      return this.course.materials.filter((material) => {
        if (this.user.is_admin || this.user.is_teacher) {
          return true;
        }

        return (
          material.year == this.user.year && material.school == this.user.school
        );
      });
    },
  },
};
</script>
<style>
.bg-white {
  background-color: white;
}

.v-card__title {
  font-size: 1rem;
}
</style>
