<template>
  <div class="background">
    <v-container class="mx-auto" v-if="!isFetching">
      <v-row>
        <v-col cols="0" sm="1" md="2" lg="3" xl="4"> </v-col>
        <v-col cols="12" sm="10" md="8" lg="6" xl="4">
          <v-row class="mt-4">
            <v-col cols="12">
              <v-sheet
                elevation="3"
                class="px-4 py-4 mb-2 rounded-lg"
                :ripple="false"
              >
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12">
                      <h2 class="text-h5 text-center">Création de cours</h2>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n2">
                    <v-col cols="12">
                      <div class="text-left">
                        <span class="text-h6 font-weight-bold">Tuteur:</span>
                        <span> {{ user.firstname }} {{ user.lastname }}</span>
                      </div>
                      <div class="text-left">
                        <span class="text-h6 font-weight-bold">École:</span>
                        <span> {{ user.school }}</span>
                      </div>
                      <div class="text-left">
                        <span class="text-h6 font-weight-bold">Année:</span>
                        <span> {{ user.year }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="my-2"></v-divider>
                  <v-row class="mt-4">
                    <v-col cols="12">
                      <div class="text-left">
                        <v-menu
                          v-model="date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formatedDate"
                              :min="now"
                              label="Date du cours"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            :min="now"
                            :first-day-of-week="1"
                            @input="date_menu = false"
                            locale="fr-fr"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div class="text-left">
                        <v-menu
                          ref="start_menu"
                          v-model="start_time_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="start_hour"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_hour"
                              label="Heure de début"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="start_time_menu"
                            v-model="start_hour"
                            full-width
                            format="24hr"
                            :allowed-minutes="allowedStep"
                            @change="updateEndHour(start_hour)"
                            @click:minute="$refs.start_menu.save(start_hour)"
                          ></v-time-picker>
                        </v-menu>
                      </div>
                      <div class="text-left">
                        <v-menu
                          ref="end_menu"
                          v-model="end_time_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="end_hour"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end_hour"
                              label="Heure de fin"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="end_time_menu"
                            v-model="end_hour"
                            full-width
                            format="24hr"
                            :allowed-minutes="allowedStep"
                            @click:minute="$refs.end_menu.save(end_hour)"
                          ></v-time-picker>
                        </v-menu>
                      </div>
                      <div class="text-left">
                        <v-select
                          :items="studentsNumbers"
                          v-model="selectedStudentsNumbers"
                          label="Nombre de tutoré max"
                          prepend-icon="mdi-school"
                          required
                        ></v-select>
                      </div>
                      <div class="text-left d-flex mt-n2">
                        <v-icon>mdi-cake</v-icon>
                        <div>
                          <v-label>Année des matières</v-label>
                          <div class="d-flex mt-n4">
                            <v-checkbox
                              v-for="level in possible_years"
                              v-model="course_years"
                              :key="level"
                              :label="level"
                              :value="level"
                              :rules="year_rule()"
                              @change="makeMaterialComply()"
                              class="mx-2"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                      <div class="text-left mt-2">
                        <v-autocomplete
                          :items="filteredMaterials"
                          item-text="name"
                          item-value="_id"
                          prepend-icon="mdi-book"
                          multiple
                          large
                          dense
                          return-object
                          label="Matière(s) où je vais aider"
                          v-model="course_material"
                          :rules="material_rule()"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              class="mx-1 my-1"
                              @click="data.select"
                              @click:close="remove(data.item)"
                            >
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div class="text-left d-flex mt-n2">
                        <v-icon>mdi-map-marker</v-icon>
                        <div>
                          <v-label>Localisation voulue</v-label>
                          <div class="d-flex mt-n4">
                            <v-checkbox
                              v-for="location in possible_location"
                              v-model="selected_location"
                              :key="location"
                              :label="location"
                              :value="location"
                              :rules="checkbox_rule()"
                              class="mx-2"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <ValidateErrorButtons
                      @successClicked="validate()"
                      @errorClicked="$router.go(-1)"
                    >
                      <template v-slot:error>ANNULER LA CREATION</template>
                      <template v-slot:success>VALIDER</template>
                    </ValidateErrorButtons>
                  </v-row>
                </v-form>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" sm="1" md="2" lg="3" xl="4"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ValidateErrorButtons from "../components/ValidateErrorButtons.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "CreateCourse",
  components: {
    ValidateErrorButtons,
  },
  data: () => ({
    now: dayjs().add(2, "day").format("YYYY-MM-DD"),
    date: new Date(
      dayjs().add(2, "day").toDate() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    isFetching: true,
    start_hour: dayjs(new Date(Date.now())).format("HH:mm"),
    end_hour: dayjs(new Date(Date.now()))
      .add(1, "hour")
      .add(30, "minute")
      .format("HH:mm"),

    date_menu: false,
    start_time_menu: false,
    end_time_menu: false,

    studentsNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    selectedStudentsNumbers: 3,

    course_material: [],
    course_years: [],

    possible_years: ["A1", "A2", "A3", "A4", "A5"],

    possible_location: ["En ligne", "Présentiel"],
    selected_location: [],
  }),
  methods: {
    validate: async function () {
      //const id = this.user._id;
      if (this.$refs.form.validate()) {
        let payload = {
          years: this.course_years,
          materials: this.course_material.map((material) => {
            return material._id;
          }),
          format_preference: this.selected_location,
          start_date: this.start_date,
          end_date: this.end_date,
          number_of_student_max: this.selectedStudentsNumbers,
        };
        if (this.$route.params.memberid != undefined) {
          payload.teacher = this.user._id;

          this.$store
            .dispatch("admin/createCourse", payload)
            .then(() => {
              this.$router.go(-1);
            })
            .catch(() => {});
        } else {
          this.$store
            .dispatch("user/createCourse", payload)
            .then(() => {
              this.$router.go(-1);
            })
            .catch(() => {});
        }
      }
    },
    allowedStep: (m) => m % 5 === 0,
    updateEndHour: function (start_hour) {
      this.end_hour = dayjs(new Date(`${this.date}T${start_hour}:00`))
        .add(1, "hour")
        .add(30, "minute")
        .format("HH:mm");
    },
    makeMaterialComply() {
      this.course_material = this.course_material.filter((material) => {
        return (
          this.course_years.includes(material.year) &&
          material.school == this.user.school
        );
      });
    },
    remove(item) {
      const index = this.course_material
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.course_material.splice(index, 1);
    },
    checkbox_rule: function () {
      return [
        this.selected_location.length > 0 ||
          "Au moins un des champs doit être sélectionné",
      ];
    },
    material_rule: function () {
      return [
        this.course_material.length > 0 ||
          "Au moins une des matières doit être sélectionné",
      ];
    },
    year_rule: function () {
      return [
        this.course_years.length > 0 ||
          "Au moins une des matières doit être sélectionné",
      ];
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      specificUser: "admin/getSpecificUser",
      materials: "materials/getMaterials",
    }),
    filteredMaterials() {
      return this.materials.filter((material) => {
        return (
          this.course_years.includes(material.year) &&
          material.school == this.user.school
        );
      });
    },
    changeFormat: function () {
      if (this.checkboxes === "presentiel") {
        return true;
      } else {
        return false;
      }
    },
    formatedDate: function () {
      return dayjs(this.date).format("DD/MM/YYYY");
    },
    start_date: function () {
      return new Date(this.date + "T" + this.start_hour);
    },
    end_date: function () {
      let end_date = new Date(this.date + "T" + this.end_hour);
      if (this.start_date > end_date) {
        end_date = dayjs(end_date).add(1, "day").toDate();
      }
      return end_date;
    },
    user: function () {
      if (this.$route.params.memberid == undefined) {
        return this.currentUser;
      } else {
        return this.specificUser;
      }
    },
  },
  beforeMount() {
    //Refresh materials

    if (this.$route.params.memberid == undefined) {
      this.$store
        .dispatch("materials/materials", {
          limit: 10000,
          page: 1,
          is_displayed: true,
          school: this.user.school,
        })
        .finally(() => {
          this.isFetching = false;
        });
      this.course_material = this.user.teacher.materials.filter((material) => {
        return (
          this.user.teacher.years.includes(material.year) &&
          material.school == this.user.school
        );
      });
      this.course_years = this.user.teacher.years;
    } else {
      this.$store
        .dispatch("admin/getUserById", this.$route.params.memberid)
        .finally(() => {
          this.$store
            .dispatch("materials/materials", {
              limit: 10000,
              page: 1,
              is_displayed: true,
              school: this.user.school,
            })
            .finally(() => {
              this.isFetching = false;
              this.course_material = this.user.teacher.materials;
              this.course_years = this.user.teacher.years;
            });
        });
    }
  },
};
</script>

<style>
#subjects .v-input {
  width: 100% !important;
}
</style>
