<template>
  <section class="home d-flex flex-md-row justify-space-around align-center">
    <v-sheet
      class="pa-10 pt-16 mx-auto"
      color="rgb(239,239,239,0.44)"
      elevation="1"
      rounded="lg"
      width="40%"
      max-width="600px"
      min-width="400px"
    >
      <h1 class="text-h3">Bienvenue sur notre plateforme</h1>
      <p class="text-h5 grey--text text--darken-1 my-6">
        Notre mission est simple : aider gratuitement les étudiants des 3 écoles
        (ESILV, EMLV, IIM) dans leurs cours et leurs projets au travers de
        séances de soutien réalisées par nos étudiants tuteurs.
      </p>
      <div class="text-center mt-8">
        <v-btn elevation="0" color="#C08E20">
          <a
            :href="
              'https://adfs.devinci.fr/adfs/oauth2/authorize?client_id=dff17dde-bbac-4740-bfe8-dfef4324a42c&response_type=id_token+token&redirect_uri=https://leolearning.fr/verify&scope=openid&response_mode=fragment&nonce=' +
              nonce
            "
          >
            <v-icon size="28" left> mdi-microsoft </v-icon>
            Se connecter avec LeoID
          </a>
        </v-btn>
      </div>
    </v-sheet>
    <img
      class="home_img ml-auto"
      src="../assets/home.png"
      alt="students laughing"
    />
  </section>
</template>

<script>
export default {
  name: "Home",
  computed: {
    nonce() {
      let result = Math.ceil(Math.random() * (100000000 - 100000 + 1)) + 1000;
      return result;
    },
  },
};
</script>
<style>
.home {
  background-color: #d6d6d6;
  background-image: url("../assets/logo_ll_low-opacity.png");
  background-attachment: local;
  background-position: -320px -10%;
}

.home_img {
  object-fit: cover;
  width: 50vw;
  height: 100%;
  overflow: hidden;
}

.v-application .v-btn a,
.v-application .v-btn a:visited {
  color: #fff;
  text-decoration: none;
}
</style>
