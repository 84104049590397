<template>
  <v-card>
    <v-icon v-on:click.stop="$emit('close')" large class="closeIcon mt-3 mx-2"
      >mdi-close</v-icon
    >

    <v-card-title class="text-h5">
      Modification des préférences tuteurs
    </v-card-title>
    <v-form class="mb-2 px-3 py-3">
      <div class="text-left d-flex mb-n1">
        <v-icon>mdi-cake</v-icon>
        <div>
          <v-label>Année des matières</v-label>
          <div class="d-flex mt-n4">
            <v-checkbox
              v-for="level in levels"
              v-model="user.teacher.years"
              :key="level"
              :label="level"
              :value="level"
              class="mx-2"
            ></v-checkbox>
          </div>
        </div>
      </div>
      <div class="d-flex mt-5">
        <v-autocomplete
          :items="filteredMaterials"
          item-text="name"
          item-value="_id"
          prepend-icon="mdi-book"
          multiple
          large
          dense
          return-object
          label="Matières où je peux aider"
          v-model="user.teacher.materials"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              class="mx-1 my-1"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <ValidateErrorButtons
        @successClicked="validate()"
        @errorClicked="$emit('close')"
        class="mt-1"
      >
        <template v-slot:error>ANNULER</template>
        <template v-slot:success>VALIDER</template>
      </ValidateErrorButtons>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ValidateErrorButtons from "./ValidateErrorButtons.vue";

export default {
  name: "TeacherProfileParams",
  components: {
    ValidateErrorButtons,
  },
  props: {
    user: Object,
  },
  data: () => ({
    //for teacher years
    levels: ["A1", "A2", "A3", "A4", "A5"],
  }),
  methods: {
    validate: function () {
      let payload = {
        _id: this.user._id,
        years: this.user.teacher.years,
        materials: this.user.teacher.materials.map((material) => {
          return material._id;
        }),
      };
      this.$store.dispatch("user/patchTeacher", payload).finally(() => {
        if (this.user._id == this.currentUser._id) {
          this.$store.dispatch("user/user", this.user);
        }

        const topEndMessage = {
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        };
        this.$swal.mixin(topEndMessage).fire({
          icon: "success",
          title: "Information modifié avec succès",
        });
        this.$emit("close");
      });
    },
    remove(item) {
      const index = this.user.teacher.materials
        .map((course) => {
          return course._id;
        })
        .indexOf(item._id);
      if (index >= 0) this.user.teacher.materials.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
      materials: "materials/getMaterials",
    }),
    filteredMaterials() {
      return this.materials.filter((material) => {
        return this.user.teacher.years.includes(material.year);
      });
    },
  },
  beforeMount() {
    //Refresh materials
    this.$store.dispatch("materials/materials", {
      limit: 10000,
      page: 1,
      is_displayed: true,
      school: this.user.school,
    });
  },
};
</script>

<style scoped>
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
