<template>
  <div class="background">
    <v-container>
      <v-row>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
        <v-col>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-card elevation="2" height="750" class="px-4 py-4 mb-10">
                <v-tabs v-model="tab" grow show-arrows>
                  <v-tab href="#users"> Utilisateurs </v-tab>
                  <v-tab-item value="users">
                    <MemberViewer />
                  </v-tab-item>
                  <v-tab href="#courses"> Cours </v-tab>
                  <v-tab-item value="courses">
                    <CourseViewer />
                  </v-tab-item>
                  <v-tab href="#matieres"> Matières </v-tab>
                  <v-tab-item value="matieres">
                    <MaterialViewer />
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="d-none d-xl-flex"> </v-col>
      </v-row>
      <v-btn
        class="d-flex justify-space-around exportButton"
        color="green"
        @click="dialog_edit = true"
      >
        <span>Exporter</span>
        <v-icon class="ml-1 mt-0.5" size="20">mdi-table-arrow-right</v-icon>
      </v-btn>
      <v-dialog v-model="dialog_edit" width="600">
        <DialogExport @close="dialog_edit = false" />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MemberViewer from "../components/MemberViewer.vue";
import CourseViewer from "../components/CourseViewer.vue";
import MaterialViewer from "../components/MaterialViewer.vue";
import DialogExport from "../components/DialogExport.vue";

export default {
  name: "Admin",

  components: { MemberViewer, CourseViewer, MaterialViewer, DialogExport },

  data: () => ({
    scrollInvoked: 0,
    dialog_edit: false,
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    schoolColor: function () {
      return this.user.school;
    },
    height: function () {
      if (!this.isMounted) return;
      return this.$refs.master.clientHeight;
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  methods: {
    returnSchool() {
      return this.user.school;
    },
    onScroll() {
      this.scrollInvoked++;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style>
@media screen and (max-width: 1200px) {
  .responsive {
    display: flex;
    flex-direction: column;
  }

  .stats {
    display: flex;
    flex-direction: column;
  }
}

.exportButton {
  position: absolute;
  top: 11.5%;
  left: 1.5%;
}
</style>
