//import $axios from "../plugins/axios";
import Vue from "vue";

const topEndMessage = {
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Vue.swal.stopTimer);
    toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
  },
};

export default {
  namespaced: true,
  state: {
    courses: [],
    course: {},
  },
  mutations: {
    COURSES(state, courses) {
      state.courses = courses;
    },
    COURSE(state, course) {
      state.course = course;
    },
  },
  actions: {
    course(context, course) {
      context.commit("COURSE", course);
    },
    courses(context, parameters) {
      Vue.axios
        .get("courses/", {
          params: parameters,
        })
        .then((response) => {
          context.commit("COURSES", response.data.results);
        })
        .catch((err) => {
          console.log(err.response.data);
          Vue.swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Quelque chose c'est mal passé 🤔",
            footer:
              "Si le problème persiste contacte LéoLearning avec le code: 9",
          });
        });
    },
    patchCourse(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch("courses/" + data.course_id, data)
          .then((response) => {
            console.log(response.data);
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Le changement a bien été effectué",
            });
            resolve(response.data.course);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 22",
            });
            reject();
          });
      });
    },
    swapRefused(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch(
            "courses/" + data.course_id + "/swap-refused/" + data.application_id
          )
          .then((response) => {
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Le changement a bien été effectué",
            });
            resolve(response.data.course);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 21",
            });
            reject(err);
          });
      });
    },
    swapMissing(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .patch(
            "courses/" + data.course_id + "/swap-missing/" + data.application_id
          )
          .then((response) => {
            console.log(response.data);
            Vue.swal.mixin(topEndMessage).fire({
              icon: "success",
              title: "Le changement a bien été effectué",
            });
            resolve(response.data.course);
          })
          .catch((err) => {
            console.log(err.response.data);
            Vue.swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose c'est mal passé 🤔",
              footer:
                "Si le problème persiste contacte LéoLearning avec le code: 21",
            });
            reject();
          });
      });
    },
  },
  getters: {
    getCourse(state) {
      return state.course;
    },
    getCourses(state) {
      return state.courses;
    },
  },
};
