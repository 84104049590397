<template>
  <div>
    <h3>
      Profil :
      <v-btn
        v-if="currentUser.is_admin"
        @click="dialog_edit = true"
        fab
        small
        color="primary"
      >
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </h3>

    <v-dialog v-model="dialog_edit" width="600">
      <DialogEditUser
        @close="dialog_edit = false"
        :adminMod="adminMod"
        :user="user"
      />
    </v-dialog>
    <div class="pa-4">
      <p>
        {{ user.firstname + " " + user.lastname }} - {{ user.email }}
        <v-btn
          v-if="currentUser.is_admin"
          class="copy"
          fab
          small
          elevation="0"
          @click="copyToClipboard(user.email)"
        >
          <v-icon> mdi-content-copy </v-icon>
        </v-btn>
      </p>
    </div>
    <h3>Formation :</h3>
    <div class="d-flex align-left pa-4">
      <img
        :src="require(`../assets/Logo_${user.school}_fd_couleur.png`)"
        alt="school_logo"
        height="50"
        width="50"
      />
      <p class="mt-3 ml-4">{{ user.school }} - {{ user.year }}</p>
    </div>
    <div>
      <h3 class="font-weight-medium">Status :</h3>
      <p class="mt-3 ml-4">{{ statut }}</p>
    </div>
    <v-snackbar v-model="copy_clipboard" :timeout="2000" color="success">
      <v-icon>mdi-check</v-icon> Copié dans le presse papier
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="copy_clipboard = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogEditUser from "../components/DialogEditUser.vue";

export default {
  name: "Bio",
  props: {
    user: Object,
    adminMod: Boolean,
  },
  components: {
    DialogEditUser,
  },
  data: () => ({
    dialog_edit: false,
    copy_clipboard: false,
  }),
  methods: {
    copyToClipboard(str) {
      navigator.clipboard.writeText(str);
      this.copy_clipboard = true;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user/getUser",
    }),
    statut: function () {
      let res = "";
      if (this.user.is_student) {
        res = "Étudiant";
      }
      if (this.user.is_teacher) {
        res = "Tuteur";
      }
      if (this.user.is_admin) {
        res = "Administrateur";
      }
      return res;
    },
  },
};
</script>

<style scoped>
.copy:before {
  background: none;
}
</style>
